import metaLogo from "../../../assets/tools-logo/meta-logo.png";
import hootSuiteLogo from "../../../assets/tools-logo/Hootsuite-Logo.png";
import adViewLogo from "../../../assets/tools-logo/adview-logo.png";
import bufferLogo from "../../../assets/tools-logo/Buffer-Logo.png";

const fbAddsInfo = {
  heroSection: {
    firstHeaderText: "Facebook",
    secondHeaderText: "реклама",
    textArr: [
      "Facebook продължава да бъде кралят на социалните медии, заедно с Instagram, неговият по-малък брат в семейството на Meta. Поради последните промени в алгоритъма за показване на съдържание в тези социални мрежи, просто не може да си позволите да не рекламирате там. Органичните показвания на публикациите достигат до съвсем малък брой хора - между 4-5.2% и тенденцията е това да продължава.",
    ],
  },
  purpleSectionText: {
    header: "Свържете се с хората чрез Facebook реклама",
    textArr: [
      "Има и добри новини - и двете социални мрежи предоставят широк набор от критерии, по които да се селектира аудиторията, на която искате да покажете своя бизнес чрез реклама. Но как да стане това е работа на професионалистите, които знаят как да насочат рекламата ефективно според бюджета, постигайки най-добри резултати по показатели като Click-through rate, Cost per Result, CPC, CPM, Video views и още.",
      "От холистична рекламна гледна точка, други платформи и мрежи винаги трябва да бъдат допълнение към провеждането на рекламна кампания във Facebook, като например Instagram. А най-важният въпрос е “защо”? ",
      "Веднага даваме отговор: Вашата аудитория е там. Пионерът на социалните медии Facebook има повече от 2,38 милиарда активни потребители месечно по целия свят – повече от всяка друга платформа за социални медии. Instagram може да се похвали с 1 милиард активни потребители месечно. По-важното е, че тези потребители не са за кратко в мрежата. Вашите настоящи и бъдещи клиенти и клиенти са в платформата средно около 2 часа и половина дневно в социалните мрежи! Това е време, в което всеки се бори за тяхното внимание и осъществяване на връзка с бранда, която да породи необходимата ангажираност и продажба, ако рекламата е била добра.",
      "А ако не е била, значи трябва да смените агенцията.",
    ],
  },
  whiteSectionText: {
    header: "Индивидуална стратегия за Facebook реклама",
    textArr: [
      "Като агенция, създадена в разгара на дигиталната ера, и движена от хора, израснали със социалните медии, ние не само разбираме екосистемата, тя вече е част от нашето ДНК. Екипът ни работи активно по управлението на Facebook реклама, Instagram реклама и управление на реклами в Google, защото всеки един от трите вида реклама може да доведе до добри финансови резултати за Вашия бранд при сравнително малка към средна инвестиция. ",
      "Независимо дали става дума за адаптивни реклами при търсене, динамични реклами при търсене, дисплейни реклами или други, FinestCopy е Вашата рекламна агенция във Facebook и Instagram за интегрирани рекламни решения. Има много фактори, които определят успеха на рекламата и ще се радваме да ги обсъдим с Вас, за да предложим най-подходящите рекламни инструменти от платформите на Meta.",
    ],
  },
  lightPurpleSection: {
    header: "Използвани технологии",
    logos: [metaLogo, hootSuiteLogo, adViewLogo, bufferLogo],
  },
};

export default fbAddsInfo;
