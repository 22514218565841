import lifeStoreMainImg from "../../assets/img/lifestore-main2.png";
import lifeStoreMockup from "../../assets/img/ls-2.png";
import herbaMedicaMainImg from "../../assets/img/herbamedica-main-mopckup.png";
import herbaMedicaMockup from "../../assets/img/herbamedica-1.png";
import detoxCenterMain from "../../assets/img/detoxCenterMain.png";
import detoxCenterMockUp from "../../assets/img/detoxcenter-1-min.png";
import detoxCenterLogo from "../../assets/img/detox-center-logo.png";
import sanatIOLogo from "../../assets/img/snatio-logo.png";
import sanatIOMainImg from "../../assets/img/sanatio-main2.png";
import sanatIOMockUp from "../../assets/img/sanat.io-tiny.png";
import cloudCartMainImg from "../../assets/img/cloudcart-main-mockup.png";
import cloudCartMockup from "../../assets/img/cloudcart-mockup.png";
import bruteMainImg from "../../assets/img/brute-main-mockup.png";
import bruteMockup from "../../assets/img/brute-mockup.png";
import stariqChinarLogo from "../../assets/img/chinar-logo.png";
import stariqChinarMainImg from "../../assets/img/staria-chinar-main-mockup.png";
import stariqChinarMockup from "../../assets/img/staria-chinar-mockup.png";
import freshHolidayLogo from "../../assets/img/fresh-holiday-logo.png";
import freshHolidayMainImg from "../../assets/img/fresh-holiday-main-mockup.png";
import freshHolydayMockup from "../../assets/img/fresh-holiday-mockup.png";
import avivLogo from "../../assets/img/aviv-logo.png";
import avivMainImg from "../../assets/img/aviv-main-mockup.png";
import avivMockup from "../../assets/img/aviv-mockup.png";
import jackotMainImg from "../../assets/img/jakot3.png";
import jackotMockup from "../../assets/img/jakot2.png";
import jackotLogo from "../../assets/img/jakot-logo.png";
import tiandeLogo from "../../assets/img/tiande-logo.png";
import videoNabludenieLogo from "../../assets/img/videoNab-logo.png";
import TiandeMain from "../../assets/img/sofiamytiande2.png";
import TiandeMockup from "../../assets/img/sofiamytiande3.png";
import VideonabludenieMain from "../../assets/img/Videonabliudenie2.png";
import VideonabludenieMockup from "../../assets/img/Videonabliudenie3.png";

const portfolioClientInfo = {
  lifeStore: {
    mainHeaderText: "Lifestore",
    mainImg: lifeStoreMainImg,
    mockupImg: lifeStoreMockup,
    clientData: {
      logo: "https://static.wixstatic.com/media/6f9d1e_2a815775fea14528a998f20a95dd0c9b~mv2.png/v1/fill/w_300,h_100,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Logo_Lifestore_final.png",
      name: "Lifestore bg",
      website: "https://lifestore.bg ",
      year: "2017 - в процес",
      category: "Онлайн магазини",
      header: "Относно проект Lifestore",
      headerAlign: "",
      textArr: [
        "Lifestore беше един от първите клиенти, който ни се довери през 2017 година. С тях започнахме от нулата -  цялостна стратегия за маркетинг чрез съдържание, която се състои от подготовка на SEO оптимизирани продуктови описания, SEO оптимизирани блог статии, текстове за рекламни материали, печатни материали, брошури и други. ",
        "Продължаваме да работим за популяризиране на бранда чрез текстово съдържание, като от началото на работата ни сме подготвили за Lifestore повече от 400 блог публикации, над 250 продуктови описания и сме реализирали (някакъв процент посещения на сайта, сесии или други).",
        "Чрез дългосрочна маркетинг стратегия за съдържанието помагаме на онлайн магазин Lifestore да продължава да расте и да реализира устойчив ръст на продажбите. За нас е удоволствие, че допринасяме за растежа им през всички тези години с професионални маркетинг решения. ",
      ],
      textColor: "section-text-color-black",
      services: [
        "SEO съдържание - продуктови описания, блог публикации, други ",
        "Ежемесечна поддръжка на блог на уебсайт",
        "Подготовка на текстове за YouTube канал",
        "Платформа: WordPress, Opencart",
      ],
    },
  },
  herbaMedica: {
    mainHeaderText: "Herbamedica",
    mainImg: herbaMedicaMainImg,
    mockupImg: herbaMedicaMockup,
    clientData: {
      logo: "https://www.herbamedica.info/public/assets/images/logo.png",
      name: "Herbamedica",
      website: "https://herbamedicabg.com/",
      year: "2017 - в процес",
      category: "Онлайн магазини",
      header: "Относно проект Herbamedica",
      headerAlign: "",
      textArr: [
        "Herbamedica беше вторият ни клиент с онлайн магазин, който ни се довери през 2017 година. Брандът има своята предистория с над 20 години присъствие на пазара на натурални продукти за здраве и хранителни добавки. За тях подготвихме и изпълнихме цялостна стратегия за маркетинг чрез съдържание, която включва подготовка на SEO оптимизирани продуктови описания, SEO оптимизирани блог статии, текстове за рекламни кампании, печатни материали, брошури и други. ",
        "Продължаваме да работим за популяризиране на бранда чрез текстово съдържание, като от началото на работата ни сме създали повече от 600 блог публикации, над 250 продуктови описания и сме реализирали обем от 55 хиляди ключови думи, над 3.5 хиляди от които държат първо, второ и трето място в резултатите от търсенето..",
        "Постоянното обновяване на асортимента и растежът на онлайн магазина изискват дългосрочна маркетинг стратегия за съдържанието, която продължаваме да прилагаме за Хербамедика.",
      ],
      textColor: "section-text-color-black",
      services: [
        "SEO съдържание - продуктови описания, блог публикации, други ",
        "Текстово и визуално съдържание за рекламни кампании ",
        "Ежемесечна поддръжка на блог на уебсайт",
        "Подготовка на съдържание за лендинг страници",
        "Копирайтинг",
        "Платформа: WordPress, Opencart",
      ],
    },
  },
  detoxCenter: {
    mainHeaderText: "Detox Center",
    mainImg: detoxCenterMain,
    mockupImg: detoxCenterMockUp,
    clientData: {
      logo: detoxCenterLogo,
      name: "DeTox Center",
      website: " https://detoxcenter.eu/",
      year: "2017 - в процес",
      category: "Корпоративен уебсайт",
      header: "Относно проект DeTox Center",
      headerAlign: "",
      textArr: [
        "DeTox Center е медицински център, профилиран в диагностиката и терапията на широк спектър заболявания чрез най-модерни холистични методи. Спектърът от услуги, които медицинският център предлага в своите обекти в София и Бургас, е впечатляващ и постоянно е разширяван с иновативни съвременни процедури.",
        "За да запознаем потенциалната аудитория с всички тези услуги предприехме цялостна стратегия за маркетинг чрез съдържание. Нейното осъществяване започна през 2017 година, като оттогава досега сме създали повече от 300 броя описания на услуги, включително диагностични процедури, терапии, лабораторни изследвания, масажи и други, които са част от портфолиото на медицинския център, както и ежемесечно публикуване на блог статии. Всички изброени до тук материали допринасят за органичното класиране на уеб сайта в резултатите от търсенето и акумулирането на над 30 хил. ключови думи класирани в SERP, от които над 1400 заемат първо до трето място.",
        "Продължаваме да си сътрудничим с DeTox Center, осигурявайки по-добра информираност на българската аудитория за иновативни диагностични и терапевтични решения от съвременната функционална медицина. Основател и част от висококвалифицирания екип на Detox Center е д-р Радослав Тошков, специалист по функционална и анти-ейдж медицина.",
      ],
      textColor: "section-text-color-black",
      services: [
        "SEO оптимизация (Оптимизация за търсачки)",
        "SEO съдържание - продуктови описания, блог публикации, други ",
        "Продуктови описания",
        "Ежемесечна поддръжка на блог на уебсайт",
        "Копирайтинг",
        "Платформа: WordPress",
      ],
    },
  },
  sanatIO: {
    mainHeaderText: "Sanat.io",
    mainImg: sanatIOMainImg,
    mockupImg: sanatIOMockUp,
    clientData: {
      logo: sanatIOLogo,
      name: "Sanat.io",
      website: " https://detoxcenter.eu/",
      year: "2017 - в процес",
      category: "Корпоративен уебсайт",
      header: "Относно проект Sanat.io",
      headerAlign: "",
      textArr: [
        "Санацио (www.sanat.io) е безплатен пациентски портал, вдъхновен от пациентската общност в България и разработен от Биосийк. Екипът зад проекта има за цел да помогне както на хората, страдащи от заболявания, така и на техните близки, да намерят достоверна информация за здравето и здравословния начин на живот.",
        "Ние допринесохме за тази цел чрез създаването на над 400 научно базирани материала на български език, както и последващия им превод на английски език, и качването в платформата.",
      ],
      textColor: "section-text-color-black",
      services: [
        "SEO съдържание - блог публикации",
        "Превод на текстове",
        "Копирайтинг",
        "Платформа: Собствена разработка на клиента",
      ],
    },
  },
  cloudCart: {
    mainHeaderText: "CloudCart",
    mainImg: cloudCartMainImg,
    mockupImg: cloudCartMockup,
    clientData: {
      logo: "https://mail.cloudcart.com/media/cc-logo-034.png",
      name: "CloudCart",
      website: "https://cloudcart.com/ ",
      year: "2021",
      category: "Saas",
      header: "Относно проект CloudCart",
      headerAlign: "",
      textArr: [
        "CloudCart е професионална eCommerce платформа с красиви дизайни и приложения, които помагат на бизнесите да продават повече. От екипа на компанията ни потърсиха относно създаване на вид маркетинг материали (lead magnets) за техните клиенти, като целта беше да привлечем и улесним търговците в подготовката на промоционални активности. За целта първо създадохме електронен речник за eCommerce, който включва над 240 термина в областта на електронната търговия и продажбите.",
        "Като втора стъпка от съвместната ни работа разработихме маркетинг календар за цяла година напред по конкретни дати за всеки месец. Благодарение на експертизата ни в сферата на дигиталния маркетинг предложихме идеи за тематични активности по дати, както и допълнителни възможности спрямо бизнес нишата. Крайният резултат виждате на снимките по-долу.",
        <a href="https://cloudcart.com/bg/guides/marketing-calendar-2021-1">
          Маркетинг календар тук.
        </a>,
      ],
      textColor: "section-text-color-black",
      services: [
        "Подготовка на маркетинг материали за уебсайт",
        "Платформа: Собствена разработка на клиента",
      ],
    },
  },
  brute: {
    mainHeaderText: "Brut(e)",
    mainImg: bruteMainImg,
    mockupImg: bruteMockup,
    clientData: {
      logo: "https://cdncloudcart.com/16965/vendors/images/1/1_600x600.png?1588513069",
      name: "Brut(e)",
      website: "https://brute.bg/ ",
      year: "2021",
      category: " Онлайн магазини",
      header: "Относно проект Brut(e)",
      headerAlign: "",
      textArr: [
        "Brut(e) е единствен по рода си онлайн магазин за спрей-ароматизатори, дифузери и други уникални продукти за автомобили. Пленяващите аромати с марка brut(e) са направени от внимателно селектирани съставки, не се тестват върху животни и имат отличителна визия. Нашата задача към brut(e) беше да пресъздадем атмосферата, която създава всеки аромат, и да я облечем в думи. Направихме го за 8 продукта на марката -  Sheih, Dream, Black, Tabacco, WOW, Shock, Future и Samurai 侍, всички без алкохол, без ацетон, без консерванти. Подготвихме и SEO текстове за началната страница на уебсайта на brut(e). ",
      ],
      textColor: "section-text-color-black",
      services: ["SEO съдържание за уебсайт", "Лендинг страници"],
    },
  },
  stariqChinar: {
    mainHeaderText: "Стария Чинар",
    mainImg: stariqChinarMainImg,
    mockupImg: stariqChinarMockup,
    clientData: {
      logo: stariqChinarLogo,
      name: "Стария Чинар",
      website: " http://stariachinar.com/",
      year: "2023",
      category: "Ресторанти и заведения",
      header: "Относно проект Стария Чинар",
      headerAlign: "",
      textArr: [
        "Емблематичната верига ресторанти Стария чинар е добре позната на варненци като оазис на добрия вкус и личното отношение. Вече двадесет години екипът на ресторантите се грижи за гостоприемното посрещане, обслужване и нагостяване на своите посетители по лични и професионални поводи. За да изразят благодарността си, създателите на Стария чинар ни потърсиха с молба да създадем цялостна ПР кампания, която отразява 20-годишния юбилей на ресторанта и включва специални събития в различни месеци, както и медийно отразяване в локални медии.",
      ],
      textColor: "section-text-color-black",
      services: [
        "Подготовка на PR кампания и материали за публикуване в местни медии",
      ],
    },
  },
  freshHolyday: {
    mainHeaderText: "Fresh Holiday",
    mainImg: freshHolidayMainImg,
    mockupImg: freshHolydayMockup,
    clientData: {
      logo: freshHolidayLogo,
      name: "Fresh Holiday",
      website: "https://freshholiday.bg/",
      year: "2022 - в процес",
      category: "Туристически агенции",
      header: "Относно проект Fresh Holiday",
      headerAlign: "",
      textArr: [
        "Туристическа агенция Fresh Holiday е създадена през 2013 година от амбициозен и опитен екип специалисти с богати познания в сферата на туристическия пазар. От екипа на агенцията ни потърсиха, за да обновим съдържанието на уебсайта им в множество страници, включително уеб страници за почивки, екскурзии, дестинации и други. Целта на нашата съвместна работа е да създадем уникални SEO текстове на български език за уеб страниците на сайта, които да го придвижат в по-горни позиции в SERP.",
      ],
      textColor: "section-text-color-black",
      services: [
        "SEO съдържание за уебсайт - текстове за уеб страници, оферти, туристически пакети",
      ],
    },
  },
  aviv: {
    mainHeaderText: "AVIV - Мъжки дрехи",
    mainImg: avivMainImg,
    mockupImg: avivMockup,
    clientData: {
      logo: avivLogo,
      name: "AVIV - Мъжки дрехи",
      website: "https://aviv.bg/",
      year: "2021",
      category: "Онлайн  магазини",
      header: "Относно проект AVIV",
      headerAlign: "",
      textArr: [
        "Онлайн магазин AVIV предлага висококачествени мъжки дрехи във всяка категория и размери - мъжки ризи, мъжки сака, мъжки тениски, мъжки пуловери, аксесоари и други. Ориентирани към модерния стил и удобството, от AVIV искаха да проговорят на езика на аудиторията в новия си уебсайт и затова се обърнаха към нас и нашите копирайтъри. ",
        "Изпълнихме с удоволствие възложената задача чрез задълбочено проучване на нуждите и изискванията на съвременния мъж по отношение на облеклото преди подготовка на съдържанието за уебсайта на AVIV. Освен това, внимателно проучихме основните критерии, които се отнасят до различните видове дрехи и как мъжете следва да ги съобразят в избора си, например структура на тялото, тегло, ръст, повод и други. Включихме и любопитни факти относно различни дрехи и тяхната употреба през годините, правейки текстовете още по-интересни и увлекателни.",
      ],
      textColor: "section-text-color-black",
      services: ["SEO оптимизация (Оптимизация за търсачки)", "SEO съдържание"],
    },
  },
  jackot: {
    mainHeaderText: "Jakot Decor",
    mainImg: jackotMainImg,
    mockupImg: jackotMockup,
    clientData: {
      logo: jackotLogo,
      name: "Jakot Decor",
      website: " https://jakot.com",
      year: "2023",
      category: "Онлайн  магазини",
      header: "Относно проект Jacot Decor",
      headerAlign: "",
      textArr: [
        "Фирма Жакот (Jakot Decor) е основана през 1996 г. в град Пловдив с основна дейност търговия в строителството и интериорен дизайн. ",
        "За своя новосъздаден онлайн магазин от друга фирма, собствениците на Jakot Decor търсиха специалист, който да се справи с имплементация на Meta Business Suite & Meta Pixel за рекламни цели.",
        "Извършихме успешно тези услуги за Jakot Decor през ноември 2023 г., след което им дадохме препоръки за развитие на онлайн магазина и предложихме управление на реклама в социални мрежи. Те приеха отправеното предложение и преминахме към подготовка с необходимите настройки. Ежемесечно изпълняваме рекламна стратегия за Jacot Decor във Facebook & Instagram, за да популяризираме асортимента в онлайн магазина, както и самия бранд и неговите физически обекти.",
      ],
      textColor: "section-text-color-black",
      services: ["Facebook реклама", "Instagram реклама"],
    },
  },
  tiande: {
    mainHeaderText: "Sofia My Tiande",
    mainImg: TiandeMain,
    mockupImg: TiandeMockup,
    clientData: {
      logo: tiandeLogo,
      name: "Sofia My Tiande",
      website: "https://sofia.mytiande.bg",
      year: "2024",
      category: "Онлайн  магазини",
      header: "Относно проект Sofia My Tiande",
      headerAlign: "",
      textArr: [
        "ТианДе e козметична компания, основана през 2007 г., чиято продукция се произвежда на базата на древни тибетски и китайски рецепти за красота и здраве. Един от представителите на компанията в София се свърза с нас за популяризиране на продуктите в социални мрежи чрез онлайн магазин. Създадохме и изпълняваме стратегия за реклама в социални мрежи, която включва реклама в Meta - Facebook & Instagram. ",
      ],
      textColor: "section-text-color-black",
      services: ["Facebook реклама", "Instagram реклама", "Графичен дизайн"],
    },
  },
  videonabliudenie: {
    mainHeaderText: "Videonabliudenie.bg",
    mainImg: VideonabludenieMain,
    mockupImg: VideonabludenieMockup,
    clientData: {
      logo: videoNabludenieLogo,
      name: "Videonabliudenie.bg",
      website: "https://videonabliudenie.bg",
      year: "2024",
      category: "Фирмен уебсайт",
      header: "Относно проект Videonabliudenie.bg",
      headerAlign: "",
      textArr: [
        "Фирма Videonabliudenie.bg (ВИДЕОНАБЛЮДЕНИЕ.БГ ЕООД) е специализирана в изграждането на системи за видеонаблюдение и видеомониторинг, подходящи както за дома, така и за големи производствени помещения и бизнес сгради.",
        "Основателят на компанията търсеше агенция, която да управлява ефективно разполагаемия месечен бюджет за Google реклама (Google Ads), като осигури по-качествен уеб трафик и повече обаждания. За целта изготвихме и изпълняваме ежемесечно стратегия за Google реклама, която включва както Search кампании, така и дисплейна реклама в цялата Google Display Network.",
      ],
      textColor: "section-text-color-black",
      services: ["Google реклама "],
    },
  },
};

export default portfolioClientInfo;
