import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useNavigate, useParams } from "react-router-dom";

const EditPost = () => {
  const [title, setTitle] = useState("");
  const [summary, setSummary] = useState("");
  const [content, setContent] = useState("");

  const navigate = useNavigate();
  const { id } = useParams();
  const [articleID, setArticleID] = useState(id);

  useEffect(() => {
    fetch(`https://finest-copy-5c15d5533e67.herokuapp.com/post/${id}`).then(
      (response) => {
        response.json().then((postInfo) => {
          setTitle(postInfo.title);
          setContent(postInfo.content);
          setSummary(postInfo.summary);
        });
      }
    );
  }, []);

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"],
      ["blockquote", "code-block"],

      [{ header: 1 }, { header: 2 }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ direction: "rtl" }],

      [{ size: ["small", false, "large", "huge"] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }],
      ["link", "image"],
      [{ align: [] }],

      ["clean"],
    ],
  };

  const updatePost = async (e) => {
    e.preventDefault();

    const response = await axios.patch(
      "https://finest-copy-5c15d5533e67.herokuapp.com/post/edit/update",
      {
        title: title,
        summary: summary,
        content: content,
        id: articleID,
      }
    );
    if (response.status === 202) {
      navigate(`/post/${id}`);
    }
  };
  return (
    <div className="post-editor-wrapper">
      <h2 className="post-editor-main-header">Finest Copy Editor Panel</h2>
      <form className="post-editor-form" onSubmit={updatePost}>
        <input
          className="post-editor-inputs"
          type="title"
          placeholder="Title"
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
        />
        <input
          className="post-editor-inputs"
          type="summary"
          placeholder="Summary"
          value={summary}
          onChange={(e) => {
            setSummary(e.target.value);
          }}
        />

        <ReactQuill
          value={content}
          modules={modules}
          onChange={(newValue) => {
            setContent(newValue);
          }}
        />
        <button className="post-editor-btn" type="submit">
          Update Post
        </button>
      </form>
    </div>
  );
};

export default EditPost;
