import { format } from "date-fns";
import React from "react";
import "./ArticleCard.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const ArticleCard = ({ img, title, summary, createdAt, _id }) => {
  return (
    <div className="article-card-wrapper">
      <div className="article-card-img">
        <Link to={`/post/${_id}`}>
          <img src={img} alt={title} />
        </Link>
      </div>
      <div className="article-card-text">
        <Link to={`/post/${_id}`}>
          <h2 className="article-card-title">{title}</h2>
        </Link>
        <p className="article-card-summary">{summary}</p>
        <p className="article-card-info">
          Автор:&nbsp;<span>Betina Tsvetkova</span>
        </p>
        <time>
          <FontAwesomeIcon icon={faCalendarAlt} className="calendar-icon" />
          {format(new Date(createdAt), "d MMM yyyy")}
        </time>
      </div>
    </div>
  );
};

export default ArticleCard;
