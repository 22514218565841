import React, { useContext, useState } from "react";
import "./LoginFormComponent.scss";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../UserContext/UserContext";

const LoginFormComponent = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { setUserId } = useContext(UserContext);

  const submitHandler = async (e) => {
    e.preventDefault();
    const response = await fetch(
      "https://finest-copy-5c15d5533e67.herokuapp.com/api/v1/user/loginPanel",
      {
        method: "POST",
        body: JSON.stringify({ userName: username, password: password }),
        headers: { "Content-Type": "application/json" },
        credentials: "include",
      }
    );
    if (response.ok) {
      response.json().then((userId) => {
        setUserId(userId);
        navigate("/home");
      });
    }
  };

  return (
    <div className="form-wrapper">
      <form className="login-form" onSubmit={submitHandler}>
        <h1 className="login-header">Login</h1>
        <input
          className="login-inputs"
          type="text"
          placeholder="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          className="login-inputs"
          type="password"
          autoComplete="on"
          placeholder="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit" className="login-btn">
          Login
        </button>
      </form>
    </div>
  );
};

export default LoginFormComponent;
