import mailChimpLogo from "../../../assets/tools-logo/Mailchimp-logo.png";
import sendInBlueLogo from "../../../assets/tools-logo/sendinblue-logo.png";
import getResponseLogo from "../../../assets/tools-logo/getresponse-logo-1.png";
import klaviyoLogo from "../../../assets/tools-logo/Klaviyo-Logo.png";

const emailMarketingInfo = {
  heroSection: {
    firstHeaderText: "Имейл",
    secondHeaderText: "маркетинг",
    textArr: [
      "Ако бизнесът иска да комуникира с клиент, най-добрият начин да го направи е чрез имейл. Създаването на силна стратегия за имейл маркетинг Ви помага да достигнете и да се свържете с Вашата целева аудитория по персонализиран начин и да увеличите продажбите на достъпна цена.",
    ],
  },
  purpleSectionText: {
    header: "Имейл маркетингът е мъртъв. Да живее имейл маркетингът.",
    textArr: [
      "С това остроумно начало намекваме за предположенията, че имейл маркетингът ще изчезне с появата на социалните медии. Това обаче не се случи - по данни на Statista, през 2022 г. броят на потребителите на електронна поща в световен мащаб е приблизително 4,3 милиарда. Очаква се тази цифра да нарасне до 4,6 милиарда през 2025 г.",
      "Имейл маркетингът от гледна точка на маркетинговата стратегия е използването на имейл комуникация за популяризиране на продукти или услуги. Но не се ограничава само до това. Можем да използваме имейл маркетинга, за да развием отношения с настоящи клиенти, да проведем ремаркетинг кампания или кампания за лоялност.",
      "Имейл маркетингът позволява на бизнеса да държи клиентите си информирани за актуалните неща и съответно, да персонализира своите маркетингови съобщения. Във времето, този начин на общуване се доказа като една от най-рентабилните форми на дигитален маркетинг. Може би един от най-важните факти за имейл маркетинга е, че софтуерите, с които се управлява, позволяват проследяване на резултатите от всеки изпратен имейл, включително дали и кога е отворен от получателя, какви стъпки е извършил след това, и още много важни маркетингови данни.",
    ],
  },
  whiteSectionText: {
    header: "Ефективен и бюджетен канал за комуникация",
    textArr: [
      "Според Hubspot 86% от потребителите биха искали да получават промоционални имейли от компании, с които работят поне веднъж месечно (като 15% предпочитат ежедневни имейли). Имейлите са най-бързият начин за обявяване на (раз)продажба, споделяне на фирмени новини или ремаркетинг на текущи клиенти.",
      "Скорошни изследвания установиха, че имейл маркетингът е популярен сред поколенията, позволявайки на бизнеса да достигне до широка аудитория ефективно и евтино.",
      "За имейл маркетинга смело можем да кажем, че е мощен, убедителен и дава страхотни резултати за магазини за електронна търговия. Така че ако предлагате продукти онлайн, силно препоръчително е имейл маркетингът да бъде част от маркетинг микса на Вашия бизнес. Имейл маркетингът позволява на Вашите клиенти редовно да се ангажират с Вас и Вашите продукти в удобно за тях време, като Ви казва какво точно търсят чрез данните, които събира за тяхното поведение. А поддържането на диалог с Вашата аудитория е ключово за преминаване през следващите стъпки на покупко-продажба.",
      "В обобщение, имейл маркетингът е бърз, достъпен маркетингов канал и най-добрият инструмент за взаимоотношения и изграждане образа на марката. Само отворете пощата си и вижте колко имейли от бизнеси ще откриете там! По същия начин и Вие може да достигнете до електронните пощи на Вашите клиенти, но след правилните настройки, подготовка на имейл бюлетин и анализ на данни.",
    ],
  },
  lightPurpleSection: {
    header: "Използвани технологии",
    logos: [mailChimpLogo, sendInBlueLogo, getResponseLogo, klaviyoLogo],
  },
};

export default emailMarketingInfo;
