import React, { useState } from "react";
import "./ProcessSection.scss";
import ServiceSectionHeader from "../../../common/HeadersComponents/ServiceSectionHeader";
import { Col, Container, Row } from "react-bootstrap";
import SmallHeaderComponent from "../../../common/HeadersComponents/SmallHeaderComponent";
import ourProcessInfo from "../../../../utils/ourProcessInfo";
import ScrollTrigger from "react-scroll-trigger";
const ProcessSection = () => {
  const [isScrollIn, setIsScrollIn] = useState(false);
  return (
    <section style={{ visibility: isScrollIn ? "visible" : "hidden" }}>
      <ServiceSectionHeader
        colorType="section-header-purple center"
        headerText="Процес на работа"
        headerAlign="center"
      />

      <Container>
        <Row>
          {ourProcessInfo.map((process, index) => (
            <Col key={index + 1} className="">
              <ScrollTrigger
                onEnter={() => setIsScrollIn(true)}
                onExit={() => setIsScrollIn(false)}>
                <div
                  className={
                    isScrollIn
                      ? "animate__animated animate__flipInX animate__delay-0.8s process-title"
                      : "process-title"
                  }
                  style={{ backgroundImage: `url(${process.backgroundImg})` }}>
                  <SmallHeaderComponent
                    headerText={process.header}
                    faIcon={process.icon}
                  />
                </div>
                <p
                  className={
                    isScrollIn
                      ? "process-paragraph animate__animated animate__slideInUp animate__delay-0.8s"
                      : "process-paragraph"
                  }>
                  {process.textInfo}
                </p>
              </ScrollTrigger>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default ProcessSection;
