import AboutPage from "../components/about-page/AboutPage";
import BlogPage from "../components/blog-page/BlogPage";
import Contacts from "../components/contact-page/Contacts";
import HomePage from "../components/home-page/HomePage";
import PortfolioPage from "../components/portfolio-page/PortfolioPage";
import Copywriting from "../components/servicec-page/copywriting/Copywriting";
import EmailMarketing from "../components/servicec-page/email-marketing/EmailMarketing";
import FacebookAdd from "../components/servicec-page/fb-add/FacebookAdd";
import GoogleAdd from "../components/servicec-page/google-add/GoogleAdd";
import GraphicDesign from "../components/servicec-page/graphic-design/GraphicDesign";
import InstagramAdd from "../components/servicec-page/instagram-add/InstagramAdd";
import Seo from "../components/servicec-page/seo/Seo";
import WebDevelopment from "../components/servicec-page/web-developening/WebDevelopment";
import ContentMarketing from "../components/servicec-page/content-marketing/ContentMarketing";
import ServicesMainPage from "../components/servicec-page/services-main/ServicesMainPage";
import TimeoutModal from "../components/timeout-modal/TimeoutModal";
import LifeStore from "../components/portfolio-page/portfolio-info-pages/LifeStore/LifeStore";
import Herbamedica from "../components/portfolio-page/portfolio-info-pages/Herbamedica/Herbamedica";
import DetoxCenter from "../components/portfolio-page/portfolio-info-pages/DetoxCenter/DetoxCenter";
import Sanatio from "../components/portfolio-page/portfolio-info-pages/Sanatio/Sanatio";
import CloudCart from "../components/portfolio-page/portfolio-info-pages/CloudCart/CloudCart";
import Brute from "../components/portfolio-page/portfolio-info-pages/Brute/Brute";
import StariqChinar from "../components/portfolio-page/portfolio-info-pages/StariqChinar/StariqChinar";
import FreshHolyday from "../components/portfolio-page/portfolio-info-pages/FreshHolyday/FreshHolyday";
import Aviv from "../components/portfolio-page/portfolio-info-pages/Aviv/Aviv";
import LoginFormComponent from "../components/loginForm/LoginFormComponent";
import CreatePost from "../components/CreatePost/CreatePost";
import ArticleComponent from "../components/blog-page/ArticleComponent";
import PrivacyPolicy from "../components/Layout/Foooter/PrivacyPolicy";
import EditPost from "../components/EditPost/EditPost";
import CookiePolicy from "../components/CookieConsent/CookiePolicy";
import JackotDecor from "../components/portfolio-page/portfolio-info-pages/JackotDecor/JackotDecor";
import Tiande from "../components/portfolio-page/portfolio-info-pages/Tiande/Tiande";
import Videonabludenie from "../components/portfolio-page/portfolio-info-pages/VideoNabludenie/Videonabludenie";

const routes = {
  timeOut: {
    path: "/",
    name: "",
    element: <TimeoutModal />,
  },
  home: {
    path: "/home",
    name: "Home",
    element: <HomePage />,
  },
  services: {
    path: "/services",
    name: "Services",
    element: <ServicesMainPage />,
  },
  contentMarketing: {
    path: "/services/content-marketing",
    name: "contentMarketing",
    element: <ContentMarketing />,
  },
  copywriting: {
    path: "/services/copywriting",
    name: "Copywriting",
    element: <Copywriting />,
  },
  seo: {
    path: "/services/seo-optimization",
    name: "SEO",
    element: <Seo />,
  },
  facebookAdd: {
    path: "/services/facebookAdd",
    name: "facebookAdd",
    element: <FacebookAdd />,
  },
  instagramAdd: {
    path: "/services/instagramAdd",
    name: "instagramAdd",
    element: <InstagramAdd />,
  },
  googleAdd: {
    path: "/services/googleAdd",
    name: "googleAdd",
    element: <GoogleAdd />,
  },
  graphicDesign: {
    path: "/services/graphicDesign",
    name: "graphicDesign",
    element: <GraphicDesign />,
  },
  webDevelopment: {
    path: "/services/webDevelopment",
    name: "webDevelopment",
    element: <WebDevelopment />,
  },
  emailMarketing: {
    path: "/services/emailMarketing",
    name: "emailMarketing",
    element: <EmailMarketing />,
  },
  portfolio: {
    path: "/portfolio",
    name: "portfolio",
    element: <PortfolioPage />,
    clients: {
      lifeStore: {
        path: "/portfolio/lifeStore",
        name: "LifeStore bg",
        element: <LifeStore />,
      },
      herbaMedica: {
        path: "/portfolio/Herbamedica",
        name: "Herbamedica",
        element: <Herbamedica />,
      },
      detoxCenter: {
        path: "/portfolio/DetoxCenter",
        name: "Detox Center",
        element: <DetoxCenter />,
      },
      sanatIO: {
        path: "/portfolio/sanatio",
        name: "Sanat.io",
        element: <Sanatio />,
      },
      cloudCart: {
        path: "/portfolio/cloudCart",
        name: "CloudCart",
        element: <CloudCart />,
      },
      brute: {
        path: "/portfolio/brute",
        name: "Brute",
        element: <Brute />,
      },
      stariqChinar: {
        path: "/portfolio/stariqChinar",
        name: "Стария Чинар",
        element: <StariqChinar />,
      },
      freshHoliday: {
        path: "/portfolio/freshHoliday",
        name: "Fresh Holiday",
        element: <FreshHolyday />,
      },
      aviv: {
        path: "/portfolio/aviv",
        name: "Aviv",
        element: <Aviv />,
      },
      jakot: {
        path: "/portfolio/jackot-decor",
        name: "Jakot",
        element: <JackotDecor />,
      },
      tiande: {
        path: "/portfolio/tiande",
        name: "Sofia My Tiande",
        element: <Tiande />,
      },
      videonabludenie: {
        path: "/portfolio/videonabludenie",
        name: "Videonabliudenie",
        element: <Videonabludenie />,
      },
    },
  },
  blog: {
    path: "/blog",
    name: "blog",
    element: <BlogPage />,
    singleArticle: {
      path: "/post/:id",
      element: <ArticleComponent />,
    },
  },
  about: {
    path: "/about",
    name: "about",
    element: <AboutPage />,
  },
  contacts: {
    path: "/contacts",
    name: "contacts",
    element: <Contacts />,
  },
  login: {
    path: "/loginPanel",
    name: "login",
    element: <LoginFormComponent />,
  },
  createPost: {
    path: "/createPost",
    name: "Create Post",
    element: <CreatePost />,
  },
  privacyPolicy: {
    path: "/privacy-policy",
    name: "Privacy Policy",
    element: <PrivacyPolicy />,
  },
  editPost: {
    path: "/edit/:id",
    name: "Edit Post",
    element: <EditPost />,
  },
  cookiePolicy: {
    path: "/cookie-policy",
    name: "Cookie Policy",
    element: <CookiePolicy />,
  },
};

export default routes;
