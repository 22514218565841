const COMMENTS = [
  {
    company: "Krasy Ivanov Photography ",
    rating: 5,
    comment:
      "Прекрасно отношение и перфектно написан текст за сайт. Благодаря, Бетина! ",
    author: "Красимир Иванов",
  },
  {
    company: "AVIV.bg - Мъжки дрехи",
    rating: 5,
    comment:
      " Текстовете, които написаха за нас Finest copy, имат много голяма стойност и много добро уникално съдържание, особено към SEO на сайта. Един от малкото текстописци в България, които изцяло се фокусира върху работата си. ",
    author: "Панайот Илиев",
  },
  {
    company: "NG Contracts",
    rating: 4,
    comment:
      "Благодаря много за предоставената професионална помощ. Успех занапред! ",
    author: "Niki Georgiev  ",
  },
  {
    company: "Copywriting Titan",
    rating: 5,
    comment:
      "Агенция Finest Copy събира в едно професионална свършена работа с бързина и стил.Ако търсите услуги, които да са винаги в крак с последните тенденции за оптимизация и копирайтинг, Finest Copy е правилният избор. Успех и на добър час!",
    author: "Svetoslav Dimitrov ",
  },
  {
    company: "Kitesurfingguru ",
    rating: 5,
    comment:
      "Благодаря много за добрата работа, която вършите за нашите проекти! Използвали сме и други фрийлансъри, но от Вас винаги оставаме приятно очаровани от Вашата амбиция, бързата и много качествена работа! Винаги е важно човек да обича това, което върши! Успех и до скоро!",
    author: "Atanas Stoyankin",
  },
  {
    company: "Иван Димитров",
    rating: 5,
    comment:
      "Бетина, Благодаря Ви за поредната страхотна статия за блога ни. Статията Ви е First! Точно това, което очаквахме и което искаме да публикуваме.",
    author: "Иван Димитров",
  },
  {
    company: "Brut(e)",
    rating: 5,
    comment: "Текстовете са ТОП. Точно така, както ги искахме!",
    author: "Brut(e)",
  },
];

export default COMMENTS;
