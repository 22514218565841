import React from "react";
import PortfolioTemplate from "../utils/PortfolioTemplate";
import portfolioClientInfo from "../../../../utils/portfolioInfo/portfolioClientInfo";

const Aviv = () => {
  return (
    <PortfolioTemplate
      mainHeaderText={portfolioClientInfo.aviv.mainHeaderText}
      mainImg={portfolioClientInfo.aviv.mainImg}
      mockupImg={portfolioClientInfo.aviv.mockupImg}
      clientData={portfolioClientInfo.aviv.clientData}
    />
  );
};

export default Aviv;
