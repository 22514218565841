import React from "react";
import "./ServicesMain.scss";
import FooterMain from "../../Layout/Foooter/FooterMain";
import LightPurpleSection from "../common/LightPurpleSection";
import mockup from "../../../assets/img/mockup.png";
import CalendlyComponent from "../../home-page/CalendlyComponent";
import ClientComments from "../../home-page/ClientComments";
import "../../home-page/OurServices.scss";
import ProcessSection from "../common/ProcessComponentSection/ProcessSection";
import WhyUsSection from "../common/WhyUsSection";
import HeroSection from "../../common/HeroSection/HeroSection";
import servicesMain from "../../../utils/servicesInfo/servicesMainPage";
import MainServiceCustomSection from "../common/MainServiceCustomSection";
import heroAnimation from "../../../assets/json-animations/services-hero-animation.json";

const ServicesMainPage = () => {
  return (
    <div className="page-header">
      <HeroSection
        className="content-marketing-hero-section"
        firstHeaderText={servicesMain.heroSection.firstHeader}
        secondHeaderText={servicesMain.heroSection.secondHeader}
        textArr={servicesMain.heroSection.paragraphs}
        paddingLeftImgCol="50px"
        heroAnimation={heroAnimation}
        btnTitle="Запитване"
        btnOnHover="on-hover-purple"
        textColor="section-text-color-purple"
        textAlign="left"
      />

      <LightPurpleSection
        headerText={servicesMain.marketingStrategyDevelopmentSteps.header}
        headerColorType="section-header-purple center"
        headerAlign="center"
        textArrParagraphs={
          servicesMain.marketingStrategyDevelopmentSteps.paragraphs
        }
        textAlign="center"
        textColor="section-text-color-purple"
        image={mockup}
        imageAlt="Finest Copy"
        imageJustifyContent="center"
        paddingBottom="0px"
      />
      <MainServiceCustomSection allServicesArr={servicesMain.allServices} />
      <WhyUsSection />
      <ProcessSection />
      <ClientComments />
      <CalendlyComponent />
      <FooterMain />
    </div>
  );
};

export default ServicesMainPage;
