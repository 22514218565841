const googleAddsInfo = {
  heroSection: {
    firstHeaderText: "Google",
    secondHeaderText: "реклама",
    textArr: [
      "Колко пъти дневно влизате в Google, за да потърсите нещо? Според Oberlo, средностатистически човек извършва между три и четири онлайн търсения в Google всеки ден. Този начин на търсене е толкова удобен, че вече сме го възприели по подразбиране и това води до огромна база данни от информация, с която Google разполага за всеки потребител и неговите интереси. ",
    ],
  },
  purpleSectionText: {
    header: "Google реклама за победа над конкуренцията",
    textArr: [
      "Според друга статистика на HubSpot, търсачката на Google изпълнява почти 90% от всички търсения в световен мащаб. Това е абсолютно господство, но все пак има и други търсачки, като Bing, Yandex, Yahoo и  други. От гледна точка на еди бизнес, тази статистика показва, че 9 от 10 от Вашите възможни клиенти използват Google като потенциален портал, за да намерят и да стигнат конкретно до Вас. И отново тези 90% от всички интернет потребители виждат в даден момент реклами - или Google Ads.",
      "Помислете само за експозицията на една марка в такова пространство. Вие не само се представяте пред огромното мнозинство от клиентите си, но се показвате на видно място в търсенето (или в долната част, или отстрани, или в приложенията на Google и навсякъде в сайтове от дисплейната мрежа на Google.) По данни на Semrush, обхватът на дисплейната мрежа на Google е повече от 2 милиона уебсайта, приложения и видеоклипове, където могат да се показват реклами от Google! Потребителите, които кликнат върху реклама, има 50% по-голяма вероятност да направят покупка. Освен това, около 1/3 от кликванията отиват към тези платени реклами, които виждате над Вашите търсения.",
    ],
  },
  whiteSectionText: {
    header: "Предимствата на Google рекламата",
    textArr: [
      "Като се има предвид всичко това, рекламирането с плащане на клик (PPC) на Google може да осигури високо ROI и повече онлайн и офлайн продажби за Вашия бизнес. Прилагането на всички настройки и познаването на правилата на платформата, как потребителите да виждат персонализирани реклами за Вашата марка, е още една от услугите, които може да предоставим на професионално ниво. От нас може да очаквате практическа поддръжка, отдаден екип и мениджър на акаунти, от когото ще получавате актуална информация за месечните резултати.",
      "Ние създаваме персонализирана стратегия и се грижим за създаването на съдържанието за рекламата, настройване и тестване, докато подготвим реклама, отговаряща на уникалните бизнес нужди и заложените цели.",
    ],
  },
};

export default googleAddsInfo;
