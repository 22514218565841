import React, { useEffect, useState } from "react";
import "./BlogPage.scss";
import ArticleCard from "./ArticleCard";
import { Col, Container, Row } from "react-bootstrap";
import FooterMain from "../Layout/Foooter/FooterMain";

const BlogPage = () => {
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    fetch("https://finest-copy-5c15d5533e67.herokuapp.com/api/v1/posts").then(
      (response) => {
        response.json().then((posts) => {
          setPosts(posts);
        });
      }
    );
  }, []);
  return (
    <div className="page-header">
      <Container>
        <Row>
          <Col xxl="12">
            <h1 className="blog-header">
              Блог за дигитален маркетинг и реклама
            </h1>
          </Col>
        </Row>
        <Row>
          {posts.length > 0 &&
            posts.map((post, index) => (
              <Col key={index + 1} xxl="4">
                <ArticleCard
                  title={post.title}
                  summary={post.summary}
                  createdAt={post.createdAt}
                  img={post.image}
                  _id={post._id}
                />
              </Col>
            ))}
        </Row>
      </Container>
      <FooterMain />
    </div>
  );
};

export default BlogPage;
