import React from "react";
import { Col } from "react-bootstrap";
import CountUp from "react-countup";
import "./CounterComponent.scss";

const CounterComponent = ({ icon, title, maxCounterValue }) => {
  return (
    <Col xl="3" lg="6" md="6" sm="12">
      <div className="counter-wrapper">
        <div className="counter-icon">{icon}</div>
        <div className="counter-value">
          <h1>
            <CountUp end={maxCounterValue} duration={5} />
          </h1>
        </div>
        <h3 className="counter-title">{title}</h3>
      </div>
    </Col>
  );
};

export default CounterComponent;
