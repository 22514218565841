import React from "react";
import "./Seo.scss";
import HeroSection from "../../common/HeroSection/HeroSection";
import PurpleSection from "../common/PurpleSection";
import WhiteSection from "../common/WhiteSection";
import ClientComments from "../../home-page/ClientComments";
import CalendlyComponent from "../../home-page/CalendlyComponent";
import FooterMain from "../../Layout/Foooter/FooterMain";
import seoInfo from "../../../utils/servicesInfo/seo/seoInfo";
import LightPurpleSection from "../common/LightPurpleSection";
import sectionAnimation from "../../../assets/json-animations/seo-purple-section-animation.json";
import sectionImg from "../../../assets/img/seo-section-img.png";
import heroAnimation from "../../../assets/json-animations/seo-hero-animation.json";

const Seo = () => {
  return (
    <>
      <HeroSection
        className="content-marketing-hero-section"
        firstHeaderText={seoInfo.heroSection.firstHeaderText}
        secondHeaderText={seoInfo.heroSection.secondHeaderText}
        textArr={seoInfo.heroSection.textArr}
        paddingLeftImgCol="50px"
        heroAnimation={heroAnimation}
        btnTitle="Запитване"
        btnOnHover="on-hover-purple"
        textColor="section-text-color-purple"
        textAlign="left"
      />
      <PurpleSection
        sectionHeader={seoInfo.purpleSectionText.header}
        textArr={seoInfo.purpleSectionText.textArr}
        textColor="section-text-color-white"
        textAlign="left"
        sectionAnimation={sectionAnimation}
      />
      <WhiteSection
        sectionHeader={seoInfo.whiteSectionText.header}
        textArr={seoInfo.whiteSectionText.textArr}
        textColor="section-text-color-purple"
        textAlign="left"
        sectionImg={sectionImg}
        animationBottomPosition="5%"
        animationRightPosition="4%"
        btn={true}
      />
      <LightPurpleSection
        headerText={seoInfo.lightPurpleSection.header}
        headerAlign="center"
        headerColorType="section-header-purple"
      >
        <div className="tools-wrapper">
          {seoInfo.lightPurpleSection.logos.map((logo, index) => {
            return <img className="tool-logo-img" src={logo} key={index + 1} />;
          })}
        </div>
      </LightPurpleSection>
      <ClientComments />
      <CalendlyComponent />
      <FooterMain />
    </>
  );
};

export default Seo;
