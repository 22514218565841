import "./Navbar.scss";
import React, { useContext, useEffect, useState } from "react";
import Logo from "../../../assets/img/small-logo2.png";
import { Link, NavLink, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "./Dropdown";
import { UserContext } from "../../../UserContext/UserContext";

const Navbar = () => {
  const [dropdown, showDropDown] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const { setUserId, userId } = useContext(UserContext);
  const [showNavBar, setShowNavBar] = useState(false);
  const [stopFetching, setStopFetching] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (!stopFetching) {
      fetch(
        "https://finest-copy-5c15d5533e67.herokuapp.com/api/v1/user/profile",
        {
          credentials: "include",
        }
      )
        .then((response) => {
          response.json().then((userInfo) => {
            if (userInfo) {
              setUserId(userInfo);
            }
          });
        })
        .catch((err) => {
          if (err) {
            setStopFetching(true);
          }
        });
    }
  }, []);

  const logout = async () => {
    await fetch(
      "https://finest-copy-5c15d5533e67.herokuapp.com/api/v1/user/logout",
      {
        credentials: "include",
        method: "POST",
      }
    ).then((response) => {
      if (response.ok) {
        setUserId(null);
      }
    });
  };

  useEffect(() => {
    if (location.pathname === "/") {
      setShowNavBar(false);
    } else {
      setShowNavBar(true);
    }
  }, [location]);

  const userName = userId?.userName;

  return (
    <>
      {showNavBar && (
        <div className="nav-bar">
          <Link className="logo" to="/home">
            <img src={Logo} alt="FinestCopy" />
          </Link>
          <nav className={showMobileMenu ? "show-mobile-nav" : "desktop-nav"}>
            {userName && (
              <>
                <NavLink
                  to="/createPost"
                  exact="true"
                  className={({ isActive }) =>
                    isActive ? "active" : undefined
                  }>
                  Add Post
                </NavLink>
                <NavLink
                  to="/blog"
                  exact="true"
                  className={({ isActive }) =>
                    isActive ? "active" : undefined
                  }
                  onClick={() => setShowMobileMenu(false)}>
                  Блог
                </NavLink>
                <NavLink
                  to="/home"
                  exact="true"
                  className="contacts"
                  onClick={logout}>
                  Logout
                </NavLink>
              </>
            )}
            {!userName && (
              <>
                <NavLink
                  exact="true"
                  to="/home"
                  className={({ isActive }) =>
                    isActive ? "active" : undefined
                  }
                  end
                  onClick={() => setShowMobileMenu(false)}>
                  Начало
                </NavLink>
                <div>
                  <NavLink
                    to="/services"
                    className={({ isActive }) =>
                      isActive ? "active services" : "services"
                    }
                    exact="true"
                    onMouseEnter={() => showDropDown(true)}
                    onMouseLeave={() => showDropDown(false)}>
                    Услуги
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      className="arrow-down"
                    />
                  </NavLink>
                  {dropdown && (
                    <Dropdown
                      onClick={() => setShowMobileMenu(false)}
                      onMouseEnter={() => showDropDown(true)}
                      onMouseLeave={() => showDropDown(false)}
                    />
                  )}
                </div>
                <NavLink
                  to="/portfolio"
                  exact="true"
                  className={({ isActive }) =>
                    isActive ? "active" : undefined
                  }
                  onClick={() => setShowMobileMenu(false)}>
                  Портфолио
                </NavLink>
                <NavLink
                  to="/blog"
                  exact="true"
                  className={({ isActive }) =>
                    isActive ? "active" : undefined
                  }
                  onClick={() => setShowMobileMenu(false)}>
                  Блог
                </NavLink>
                <NavLink
                  to="/about"
                  exact="true"
                  className={({ isActive }) =>
                    isActive ? "active" : undefined
                  }
                  onClick={() => setShowMobileMenu(false)}>
                  За Нас
                </NavLink>
                <a
                  href="#footer"
                  className="contacts"
                  onClick={() => setShowMobileMenu(false)}>
                  Контакти
                </a>
              </>
            )}
          </nav>
          <div className="mobile-menu-wrapper">
            <FontAwesomeIcon
              className="mobile-menu-btn"
              icon={faBars}
              onClick={() => {
                setShowMobileMenu(!showMobileMenu);
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;
