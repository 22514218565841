import React from "react";
import PortfolioTemplate from "../utils/PortfolioTemplate";
import portfolioClientInfo from "../../../../utils/portfolioInfo/portfolioClientInfo";

const Herbamedica = () => {
  return (
    <PortfolioTemplate
      mainHeaderText={portfolioClientInfo.herbaMedica.mainHeaderText}
      mainImg={portfolioClientInfo.herbaMedica.mainImg}
      mockupImg={portfolioClientInfo.herbaMedica.mockupImg}
      clientData={portfolioClientInfo.herbaMedica.clientData}
      logo={false}
    />
  );
};

export default Herbamedica;
