import htmlLogo from "../../../assets/tools-logo/html-logo.png";
import sassLogo from "../../../assets/tools-logo/sass-logo.png";
import cssLogo from "../../../assets/tools-logo/css-logo.png";
import jsLogo from "../../../assets/tools-logo/javaScript-logo.png";
import nodeJsLogo from "../../../assets/tools-logo/node-js.png";
import expressJsLogo from "../../../assets/tools-logo/ExpressJS-logo.png";
import mongoDbLogo from "../../../assets/tools-logo/mongoDb-logo.png";
import reactLogo from "../../../assets/tools-logo/react-logo.png";
import bootstrapLogo from "../../../assets/tools-logo/Bootstrap_logo.svg.png";
import wordpressLogo from "../../../assets/tools-logo/WordPress-Logo.png";

const webDevInfo = {
  heroSection: {
    firstHeaderText: "Изработка на",
    secondHeaderText: "уебсайт",
    textArr: [
      "За всеки бизнес е изключително важно да има съвременен, професионално изглеждащ и актуален уебсайт. Технологиите се развиват изключително бързо, а това темпо не е по силите на всеки бизнес. Затова съществуват маркетинг специалистите, насочвайки бизнеса към правилните канали за популяризиране и продажби. И уебсайтът е най-важният такъв канал.",
    ],
  },
  purpleSectionText: {
    header: "Уебсайт, който работи за Вас 24/7",
    textArr: [
      "Уебсайтът е незаменим бизнес инструмент. Въпреки че технологията, използвана за създаване на уебсайтове, се е променила драматично, значението на уеб сайтовете за бизнес развитието не намалява. Точно обратното. Уебсайтът е един от най-рентабилните маркетингови инструменти, налични за малкия и среден бизнес.",
      "Отнема около 50 милисекунди (това са 0,05 секунди) на потребителите да формират мнение за Вашия уебсайт, което определя дали го харесват или не, дали ще останат или ще излязат от него. А по данни на Hubspot, 38% от хората ще спрат да разглеждат уебсайт, ако съдържанието или оформлението са непривлекателни по тяхна преценка. Според друг доклад на Statistic Brain, 47% от потребителите очакват максимум 2 секунди време за зареждане на всеки уебсайт. Отговаря ли Вашият уебсайт на тези критерии? Свържете се с нас и ще проверим! Професионално направеният и динамичен уеб сайт повишава доверието в бизнеса и помага на хората да разберат какво правите, какво предлагате и продавате. Предоставяйки цялата нужна информация на потребителите полагате основата за повече продажби и добро клиентско изживяване.",
      "Но имате ли вече собствен уебсайт, който да надграждате и развивате, и който да продава за вас 24/7? Ако нямате, ние може да създадем такъв за вас, а ако вече имате - можем да анализираме състоянието му, да оценим неговите технически показатели и съдържание, както и възможности за развитие.",
    ],
  },
  whiteSectionText: {
    header: "Персонализирани решения за изработка на уебсайт",
    textArr: [
      "За да поддържате надеждно онлайн присъствие, да имате онлайн пространство, което да е винаги достъпно за Вашите клиенти и партньори, най-добрият избор е собствен уебсайт с редовно обновяване и поддръжка. А чрез аналитични инструменти можем да проучим поведението на потребителите, които посещават уебсайта, откривайки възможности за подобрение.",
      "Със собствен уебсайт имате пълен контрол върху съдържанието си и можете да споделяте новини и информация за бизнеса по какъвто начин желаете. За разлика от социалните медии, където трябва да се съобразявате с алгоритмите и платформата, която в крайна сметка притежава всичко, което публикувате, при уебсайт съдържанието нещата стоят различно",
      "Професионално проектираният уебсайт е от съществено значение за всеки съвременен бизнес. Ето защо всеки уебсайт, създаден от нас, е изграден върху рамка, която подпомага растежа и успеха. Нашият екип за разработка и дизайн помага на Вашата компания да изгради образ на марката, поддържащ цялостно дигитално присъствие. Това може да се постигне както чрез персонализирано разработен сайт по поръчка, така и чрез уебсайт изграден с готов CMS, за да отговорим на бюджета на всяка компания. ",
    ],
  },
  lightPurpleSection: {
    header: "Използвани технологии",
    logos: [
      htmlLogo,
      cssLogo,
      sassLogo,
      bootstrapLogo,
      jsLogo,
      reactLogo,
      nodeJsLogo,
      expressJsLogo,
      mongoDbLogo,
      wordpressLogo,
    ],
  },
};

export default webDevInfo;
