import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./CreatePost.scss";
import { useNavigate } from "react-router-dom";

const CreatePost = () => {
  const [title, setTitle] = useState("");
  const [summary, setSummary] = useState("");
  const [content, setContent] = useState("");
  const [files, setFiles] = useState("");
  const navigate = useNavigate();

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"],
      ["blockquote", "code-block"],

      [{ header: 1 }, { header: 2 }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ direction: "rtl" }],

      [{ size: ["small", false, "large", "huge"] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }],
      ["link", "image"],
      [{ align: [] }],

      ["clean"],
    ],
  };

  const createNewPost = async (e) => {
    const data = new FormData();
    data.set("title", title);
    data.set("summary", summary);
    data.set("content", content);
    data.set("files", files[0]);
    e.preventDefault();
    const response = await fetch(
      "https://finest-copy-5c15d5533e67.herokuapp.com/api/v1/post",
      {
        method: "POST",
        body: data,
      }
    );
    if (response.ok) {
      navigate("/blog");
    }
  };

  return (
    <div className="post-editor-wrapper">
      <h2 className="post-editor-main-header">Finest Copy Editor Panel</h2>
      <form className="post-editor-form" onSubmit={createNewPost}>
        <input
          className="post-editor-inputs"
          type="title"
          placeholder="Title"
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
        />
        <input
          className="post-editor-inputs"
          type="summary"
          placeholder="Summary"
          value={summary}
          onChange={(e) => {
            setSummary(e.target.value);
          }}
        />
        <input
          className="post-editor-inputs"
          name="files"
          type="file"
          onChange={(e) => {
            setFiles(e.target.files);
          }}
        />
        <ReactQuill
          value={content}
          modules={modules}
          onChange={(newValue) => {
            setContent(newValue);
          }}
        />
        <button className="post-editor-btn" type="submit">
          Create Post
        </button>
      </form>
    </div>
  );
};

export default CreatePost;
