import React from "react";
import PortfolioTemplate from "../utils/PortfolioTemplate";
import portfolioClientInfo from "../../../../utils/portfolioInfo/portfolioClientInfo";

const Sanatio = () => {
  return (
    <PortfolioTemplate
      mainHeaderText={portfolioClientInfo.sanatIO.mainHeaderText}
      mainImg={portfolioClientInfo.sanatIO.mainImg}
      mockupImg={portfolioClientInfo.sanatIO.mockupImg}
      clientData={portfolioClientInfo.sanatIO.clientData}
    />
  );
};

export default Sanatio;
