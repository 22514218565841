import React, { useState } from "react";
import "./MainServiceCustomSection.scss";
import ServiceSectionButton from "../../common/ButtonComponents/ServiceSectionButton";
import ScrollTrigger from "react-scroll-trigger";

const MainServiceCustomSection = ({ allServicesArr }) => {
  const [isScrollIn, setIsScrollIn] = useState(false);
  const [isScrollInSectionTwo, setIsScrollInSectionTwo] = useState(false);
  return (
    <section className="main-services-custom-section">
      {allServicesArr.map((service, index) => {
        if (index % 2 === 0) {
          return (
            <ScrollTrigger onEnter={() => setIsScrollIn(true)}>
              <div key={index + 1} className="services-element-wrapper">
                <div
                  className="services-element-text"
                  style={{ visibility: isScrollIn ? "visible" : "hidden" }}>
                  <h3
                    className={
                      isScrollIn
                        ? "services-element-header animate__animated animate__fadeInLeft animate__delay-0.8s"
                        : "services-element-header"
                    }>
                    {service.header}
                  </h3>
                  {service.paragraphs.map((paragraph, index) => {
                    return (
                      <p
                        key={index + 1}
                        className={
                          isScrollIn
                            ? "service-element-paragraph animate__animated animate__fadeInLeft animate__delay-0.8s"
                            : "service-element-paragraph"
                        }>
                        {paragraph}
                      </p>
                    );
                  })}
                  <ServiceSectionButton
                    title="Виж Още"
                    onHover="on-hover-purple "
                    textColor="section-text-color-purple"
                    textAlign="left"
                    path={service.btnPath}
                  />
                </div>
                <div className="services-element-img-wrapper">
                  <img
                    src={service.img}
                    alt="/"
                    className={
                      isScrollIn
                        ? "animate__animated animate__backInRight animate__delay-0.8s service-element-img"
                        : "service-element-img"
                    }
                  />
                </div>
              </div>
            </ScrollTrigger>
          );
        } else {
          return (
            <ScrollTrigger onEnter={() => setIsScrollInSectionTwo(true)}>
              <div
                style={{ visibility: isScrollIn ? "visible" : "hidden" }}
                key={index + 1}
                className="services-element-wrapper reverse-on-mobile">
                <div className="services-element-img-wrapper">
                  <img
                    src={service.img}
                    alt="/"
                    className={
                      isScrollInSectionTwo
                        ? "animate__animated animate__backInLeft animate__delay-0.8s service-element-img"
                        : "service-element-img"
                    }
                  />
                </div>
                <div className="services-element-text">
                  <h3
                    className={
                      isScrollInSectionTwo
                        ? "services-element-header animate__animated animate__fadeInRight animate__delay-0.8s"
                        : "services-element-header"
                    }>
                    {service.header}
                  </h3>
                  {service.paragraphs.map((paragraph, index) => {
                    return (
                      <p
                        key={index + 1}
                        className={
                          isScrollInSectionTwo
                            ? "service-element-paragraph animate__animated animate__fadeInRight animate__delay-0.8s"
                            : "service-element-paragraph"
                        }>
                        {paragraph}
                      </p>
                    );
                  })}
                  <ServiceSectionButton
                    title="Виж Още"
                    onHover="on-hover-purple"
                    textColor="section-text-color-purple"
                    textAlign="left"
                    path={service.btnPath}
                  />
                </div>
              </div>
            </ScrollTrigger>
          );
        }
      })}
    </section>
  );
};

export default MainServiceCustomSection;
