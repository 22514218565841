import React from "react";
import portfolioClientInfo from "../../../../utils/portfolioInfo/portfolioClientInfo";
import PortfolioTemplate from "../utils/PortfolioTemplate";

const CloudCart = () => {
  return (
    <PortfolioTemplate
      mainHeaderText={portfolioClientInfo.cloudCart.mainHeaderText}
      mainImg={portfolioClientInfo.cloudCart.mainImg}
      mockupImg={portfolioClientInfo.cloudCart.mockupImg}
      clientData={portfolioClientInfo.cloudCart.clientData}
    />
  );
};

export default CloudCart;
