const aboutUs = {
  heroSection: {
    firstHeaderText: "Ние сме",
    secondHeaderText: "Finest Copy",
    textArr: [
      "Зад завесите на Finest Copy стои подбран екип от копирайтъри, специалисти по уеб дизайн, дигитален маркетинг и реклама, които преобразяват бизнес присъствието и преодоляват пропастта между въображаемите концепции и тяхната дигитална реализация.",
      "Нашият най-голям актив са нашите хора, които имат свободата да действат и творят в полза на всеки проект, с който се заемат. Ние се доверяваме на съотборниците си и ценим автономията, като същевременно следваме установените процеси и правила за работа. Така реализираме два подхода, които работят синергично и полагат основата на нашия - и Вашия - успех.",
      "Нашето мото е “Let’s Grow Together”/”Your Powerhouse for Digital Marketing Solutions”.",
      "Нашата цел е да утвърдим дигиталния маркетинг като инструмент за трансформация и стълб за устойчив растеж, съсредоточен върху изграждане на общности, полезност за хората и продажби за бизнесите.",
    ],
  },
  ourPrinciplesSection: {
    textArr: [
      "Неизменна част от нашия път през последните шест години са нашите ценности. Те са съвкупност от принципи, които диктуват фирмената философия, намаляват рисковете от попадане “в окото на бурята” и полагат стабилна основа за устойчиви резултати.",
    ],
  },
};

export default aboutUs;
