import photoshopLogo from "../../../assets/tools-logo/Adobe-Photoshop-Logo.png";
import illustratorLogo from "../../../assets/tools-logo/Illustrator-Logo.png";
import inDesignLogo from "../../../assets/tools-logo/Adobe-InDesign-Logo.png";
import figmaLogo from "../../../assets/tools-logo/figma-logo.png";

const graphicDesign = {
  heroSection: {
    firstHeaderText: "Графичен",
    secondHeaderText: "дизайн",
    textArr: [
      "Графичният дизайн е визуалната история, която разказвате за Вашата марка на Вашите клиенти и последователи. Вашата марка е личността на Вашия бизнес, е един от основните компоненти на силната марка са визуалните инструменти, които използвате, за да комуникирате невербално с аудиторията. Изображенията, цветовете, шрифтовете и дизайните, които изграждат Вашата марка, са атрибути, които имат за цел да Ви отличават, затова е толкова важно да заложите на качествен графичен дизайн.",
    ],
  },
  purpleSectionText: {
    header: "Създаване на запомняща се марка с графичен дизайн",
    textArr: [
      "Графичният дизайн и визуалният маркетинг служат за разказване на запомняща се история. Ние знаем, че визуалните активи са задължителни за комуникация на историята на марката. Писаното слово под формата на копирайтинг е мощен инструмент, но завладяващият графичен дизайн позволява на аудиторията да забележи Вашата марка, без да се налага да чете нито една дума.",
      "Този модел трябва да бъде основен приоритет за търговците, защото професионалните изображения в уебсайт, официални профили и имейл съдържание на компанията ще помогне на марката да се открои.",
      "Графичният дизайн може да вдъхне живот на всеки бизнес, презентирайки го по достъпен начин. А омниканалната маркетингова стратегия изисква синхрон между графичен дизайн, копирайтинг и съдържание в други канали, където комуникирате с потребители. Ето защо добрият графичен дизайн е толкова важен още “на първа среща”. ",
      "Визуалната идентичност на Вашата марка често е първото нещо, което Вашите клиенти ще научат за Вас, а твърде вероятно и ще запомнят. Затова нямате втори шанс за първо впечатление, с което да накарате потребителите да кликват, разглеждат и купуват.",
    ],
  },
  whiteSectionText: {
    header: "Визуална идентичност чрез уникален графичен дизайн",
    textArr: [
      "Във Finest Copy създаваме индивидуални дизайнерски визии, които отразяват мисията, визията, ценностите и представят Вашата компания по най-добрия начин. Ние помагаме на марките да се откроят със смел индивидуален дизайн, основани на идентичността на Вашата марка. И без стокови изображения!От уебсайтове до платени реклами, от печатни до дигитални, нашият екип създава активи в различни платформи и медии, за да изградим осведоменост и популярност за Вашия бизнес. Ето какви може да са продуктите на графичния дизайн:",
      "Дизайн на лого - Ново фирмено лого или актуализиране на съществуващо лого за уеб и печатни формати.",
      "Проектиране и графичен дизайн на уеб сайт – Визуалните елементи са жизненоважни, за да направите съдържанието на уебсайта интересно, ангажиращо и полезно за потребителите. Създаваме за Вас красиви визуални компоненти, които подобряват потребителското изживяване и затвърждават гласа на марка.",
      "Дизайн за печат – Креативните ни специалисти проектират дизайни за листовки, плакати, бюлетини, графики за банери и други материали, за да подобрят презентирането на продукти, услуги, специални събития и рекламни кампании.",
      "Дизайн на продуктови опаковки - Целта на ефективния дизайн на опаковката е продуктът да се отличава от останалата част от конкурентния пазар, като същевременно остава верен на идентичността на марката.",
      "UI дизайн - Дизайнерите на потребителски интерфейс (UI) работят в тясно сътрудничество с уеб дизайнери и разработчици, за да подобрят взаимодействието на потребителя с приложения или устройства.",
    ],
  },
  lightPurpleSection: {
    header: "Използвани технологии",
    logos: [photoshopLogo, illustratorLogo, inDesignLogo, figmaLogo],
  },
};

export default graphicDesign;
