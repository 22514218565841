import {
  faBriefcase,
  faGrinHearts,
  faHandshake,
  faSliders,
  faTrophy,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const whyUsInfo = [
  {
    title: "Ежедневно проследяване",
    infoText:
      "Работим за проследяване и оптимизиране на резултатите всекидневно в различните дигитални канали, в които присъстват клиентите ни.",
    iconCode: <FontAwesomeIcon icon={faSliders} />,
    id: Math.floor(Math.random() * 486748936312),
  },
  {
    title: "Собствен проектен мениджър",
    infoText:
      "Комуникацията Ви с нас ще се води само от един човек, който очертава задачите, сроковете и необходимите ресурси.",
    iconCode: <FontAwesomeIcon icon={faBriefcase} />,
    id: Math.floor(Math.random() * 486748936312),
  },
  {
    title: "Прозрачен работен процес",
    infoText:
      "Получавате пълна прозрачност относно нашите дейности и напредък с достъп до профили и месечни отчети за работа.",
    iconCode: <FontAwesomeIcon icon={faHandshake} />,
    id: Math.floor(Math.random() * 486748936312),
  },
];

const whyUsForAboutPage = [
  {
    title: "Опит",
    infoText:
      "С повече от 6 години опит и непрестанно учене (подпомогнати от над 6 хиляди изпити чаши кафе), нашият богат набор от умения и технологии за работа осигуряват комплексни решения за Вашите дигитални цели. Партнираме си с бизнеси от над 10 индустрии, сред които Автомобили, Потребителски стоки. Продажби на дребно, Фармацевтика, Развлекателни услуги и медии, Инженерство и строителство, Туризъм.",
    iconCode: <FontAwesomeIcon icon={faTrophy} />,
    id: Math.floor(Math.random() * 486748936312),
  },
  {
    title: "Всеотдайност",
    infoText:
      "Отдадеността управлява всеки аспект от начина ни на работа. Ангажираме се да предоставяме възможно най-добрите резултати за нашите партньори във всяка сфера от услугите в портфолито, независимо дали става въпрос за цялостно изграждане на уебсайт или кратко рекламно копи за единична кампания на бранда. Така не просто печелим клиенти, а ставаме дигитални партньори на проекти, в които вярваме.",
    iconCode: <FontAwesomeIcon icon={faGrinHearts} />,
    id: Math.floor(Math.random() * 486748936312),
  },
  {
    title: "Гъвкавост",
    infoText:
      "Бързата комуникация, приспособимостта към различни бизнес сфери и сътрудничеството в екип ни позволяват да адаптираме нашите процеси към Вашите нужди. Реагираме бързо на промените, без да правим компромис с качеството на работа, използвайки съвременни технологии и обучения, през които преминаваме. Така поддържаме темпото и осигуряваме висока добавена стойност за клиенти и партньори. ",
    iconCode: <FontAwesomeIcon icon={faHandshake} />,
    id: Math.floor(Math.random() * 486748936312),
  },
];

export default whyUsInfo;
export { whyUsForAboutPage };
