import React from "react";
import PortfolioTemplate from "../utils/PortfolioTemplate";
import portfolioClientInfo from "../../../../utils/portfolioInfo/portfolioClientInfo";

const FreshHolyday = () => {
  return (
    <PortfolioTemplate
      mainHeaderText={portfolioClientInfo.freshHolyday.mainHeaderText}
      mainImg={portfolioClientInfo.freshHolyday.mainImg}
      mockupImg={portfolioClientInfo.freshHolyday.mockupImg}
      clientData={portfolioClientInfo.freshHolyday.clientData}
    />
  );
};

export default FreshHolyday;
