import Navbar from "../Layout/navbar/Navbar";

import "./HomePage.scss";
import React from "react";
import PartnersSlider from "./PartnersSlider";
import WhyUsSection from "./WhyUsSection";
import OurServices from "./OurServices";
import FooterMain from "../Layout/Foooter/FooterMain";
import ClientComments from "./ClientComments";
import CalendlyComponent from "./CalendlyComponent";
import HeroSection from "../common/HeroSection/HeroSection";
import homePageHeroInfo from "../../utils/homePageHeroInfo";
import heroAnimation from "../../assets/json-animations/home-hero-animation.json";

const HomePage = () => {
  return (
    <div className="home-page" id="home">
      <Navbar />
      <HeroSection
        className="home-page-hero-section"
        firstHeaderText={homePageHeroInfo.heroSection.firstHeaderText}
        secondHeaderText={homePageHeroInfo.heroSection.secondHeaderText}
        textArr={homePageHeroInfo.heroSection.textArr}
        paddingLeftImgCol="50px"
        heroAnimation={heroAnimation}
        btnTitle="Запитване"
        btnOnHover="on-hover-purple"
        textColor="section-text-color-purple"
        textAlign="left"
      />

      <PartnersSlider />
      <WhyUsSection />
      <OurServices />
      <ClientComments />
      <CalendlyComponent />
      <FooterMain />
    </div>
  );
};

export default HomePage;
