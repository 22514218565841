import React from "react";

const Contacts = () => {
  return (
    <div className="page-header">
      <h1>Contacts</h1>
    </div>
  );
};

export default Contacts;
