import React from "react";
import PortfolioTemplate from "../utils/PortfolioTemplate";
import portfolioClientInfo from "../../../../utils/portfolioInfo/portfolioClientInfo";

const StariqChinar = () => {
  return (
    <PortfolioTemplate
      mainHeaderText={portfolioClientInfo.stariqChinar.mainHeaderText}
      mainImg={portfolioClientInfo.stariqChinar.mainImg}
      mockupImg={portfolioClientInfo.stariqChinar.mockupImg}
      clientData={portfolioClientInfo.stariqChinar.clientData}
    />
  );
};

export default StariqChinar;
