import React from "react";
import PortfolioPage from "../../PortfolioPage";
import PortfolioTemplate from "../utils/PortfolioTemplate";
import portfolioClientInfo from "../../../../utils/portfolioInfo/portfolioClientInfo";

export default function Videonabludenie() {
  return (
    <PortfolioTemplate
      mainHeaderText={portfolioClientInfo.videonabliudenie.mainHeaderText}
      clientData={portfolioClientInfo.videonabliudenie.clientData}
      mainImg={portfolioClientInfo.videonabliudenie.mainImg}
      mockupImg={portfolioClientInfo.videonabliudenie.mockupImg}
    />
  );
}
