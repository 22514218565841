import React, { useState } from "react";
import "./OurPrinciplesComponent.scss";
import { Col, Container, Row } from "react-bootstrap";
import ServiceSectionHeader from "../common/HeadersComponents/ServiceSectionHeader";
import ServicePageParagraphs from "../common/ParagraphComponents/ServicePageParagraphs";
import aboutUs from "../../utils/aboutUsInfo/aboutUsInfo";
import ourPrinciplesInfo from "../../utils/aboutUsInfo/ourPrinciplesInfo";
import PrimaryCardComponent from "../servicec-page/common/primaryCardComponent/PrimaryCardComponent";
import ScrollTrigger from "react-scroll-trigger";
const OurPrinciplesComponent = () => {
  const [isScrollIn, setIsScrollIn] = useState(false);
  return (
    <section style={{ visibility: isScrollIn ? "visible" : "hidden" }}>
      <Container>
        <Row>
          <ServiceSectionHeader
            headerAlign="center"
            colorType="section-header-purple center"
            headerText="Нашите основни принципи"
          />
          <ServicePageParagraphs
            textArr={aboutUs.ourPrinciplesSection.textArr}
            textColor="section-text-color-purple"
            textAlign="center"
          />
          {ourPrinciplesInfo.map((principle) => (
            <Col
              xxl="3"
              xl="3"
              md="6"
              key={principle.id}
              className={
                isScrollIn
                  ? "animate__animated animate__zoomInUp animate__delay-0.8s"
                  : ""
              }>
              <ScrollTrigger onEnter={() => setIsScrollIn(true)}>
                <PrimaryCardComponent
                  key={principle.id}
                  cardHeader={principle.title}
                  cardIcon={principle.iconCode}
                  cardText={principle.infoText}
                  extraClass="our-principles-cards"
                />
              </ScrollTrigger>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default OurPrinciplesComponent;
