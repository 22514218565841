const homePageHeroInfo = {
  heroSection: {
    firstHeaderText: "Правилната дестинация ",
    secondHeaderText: "за ефективни маркетинг решения ",
    textArr: [
      "Създаваме Вашия дигитален облик с модерни технологични средства за печелившо онлайн присъствие",
    ],
  },
};

export default homePageHeroInfo;
