import { Container, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./WhyUsSection.scss";
import "./OurServices.scss";
import React, { useState } from "react";
import ServiceCard from "../serviceCard/ServiceCard";
import SERVICE_CARDS_INFO from "../../utils/servicesCardInfo";
import ScrollTrigger from "react-scroll-trigger";

const OurServices = () => {
  const [isScrollIn, setIsScrollIn] = useState(false);
  const [isScrollInSectionTwo, setIsScrollInSectionTwo] = useState(false);
  const [isScrollInSectionThree, setIsScrollInSectionThree] = useState(false);

  return (
    <section className="services-section">
      <ScrollTrigger onEnter={() => setIsScrollIn(true)}>
        <h2
          className={
            isScrollIn
              ? "our-services-header animate__animated animate__fadeInLeft animate__delay-0.8s"
              : "our-services-header"
          }>
          Нашите Услуги
        </h2>
      </ScrollTrigger>

      <Container>
        <Row
          className={
            isScrollIn
              ? "animate__animated animate__zoomIn animate__delay-0.4s"
              : "hidden"
          }>
          {SERVICE_CARDS_INFO.map((cardInfo, index) => {
            if (index < 3) {
              return (
                <ServiceCard
                  cardIcon={cardInfo.iconCode}
                  cardHeader={cardInfo.title}
                  info={cardInfo.infoText}
                  key={cardInfo.id}
                  path={cardInfo.path}
                />
              );
            }
            return null;
          })}
        </Row>

        <ScrollTrigger onEnter={() => setIsScrollInSectionTwo(true)}>
          <Row
            className={
              isScrollInSectionTwo
                ? "animate__animated animate__zoomIn animate__delay-0.6s"
                : "hidden"
            }>
            {SERVICE_CARDS_INFO.map((cardInfo, index) => {
              if (index < 6 && index > 2) {
                return (
                  <ServiceCard
                    cardIcon={cardInfo.iconCode}
                    cardHeader={cardInfo.title}
                    info={cardInfo.infoText}
                    key={cardInfo.id}
                    path={cardInfo.path}
                  />
                );
              }
              return null;
            })}
          </Row>
        </ScrollTrigger>
        <ScrollTrigger onEnter={() => setIsScrollInSectionThree(true)}>
          <Row
            className={
              isScrollInSectionThree
                ? "animate__animated animate__zoomIn animate__delay-0.8s"
                : "hidden"
            }>
            {SERVICE_CARDS_INFO.map((cardInfo, index) => {
              if (index > 5) {
                return (
                  <ServiceCard
                    cardIcon={cardInfo.iconCode}
                    cardHeader={cardInfo.title}
                    info={cardInfo.infoText}
                    key={cardInfo.id}
                    path={cardInfo.path}
                  />
                );
              }
              return null;
            })}
          </Row>
        </ScrollTrigger>
      </Container>
    </section>
  );
};

export default OurServices;
