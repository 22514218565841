import React from "react";
import PortfolioTemplate from "../utils/PortfolioTemplate";
import portfolioClientInfo from "../../../../utils/portfolioInfo/portfolioClientInfo";

const Brute = () => {
  return (
    <PortfolioTemplate
      mainHeaderText={portfolioClientInfo.brute.mainHeaderText}
      mainImg={portfolioClientInfo.brute.mainImg}
      mockupImg={portfolioClientInfo.brute.mockupImg}
      clientData={portfolioClientInfo.brute.clientData}
    />
  );
};

export default Brute;
