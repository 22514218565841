import React from "react";
import "./PortfolioProjectsSection.scss";
import { Container, Row } from "react-bootstrap";
import ServiceSectionHeader from "../common/HeadersComponents/ServiceSectionHeader";
import portfolioInfo from "../../utils/portfolioInfo/portfolioInfo";
import PortfolioProjectComponent from "./PortfolioProjectComponent";
const PortfolioProjectsSection = () => {
  return (
    <div className="portfolio-projects-section">
      <ServiceSectionHeader
        headerText="НАШИТЕ ПРОЕКТИ"
        headerColorType="section-header-purple center"
        headerAlign="center"
      />
      <Container>
        <Row>
          {portfolioInfo.portfolioProjectSection.map((project, index) => {
            if (index < 3) {
              return (
                <PortfolioProjectComponent
                  key={index + 1}
                  header={project.header}
                  image={project.image}
                  path={project.path}
                />
              );
            } else if (index < 6 && index > 2) {
              return (
                <PortfolioProjectComponent
                  key={index + 1}
                  header={project.header}
                  image={project.image}
                  path={project.path}
                />
              );
            } else if (index > 5) {
              return (
                <PortfolioProjectComponent
                  key={index + 1}
                  header={project.header}
                  image={project.image}
                  path={project.path}
                />
              );
            }
            return null;
          })}
        </Row>
      </Container>
    </div>
  );
};

export default PortfolioProjectsSection;
