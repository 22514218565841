import React, { useState } from "react";
import "./ImageStyles.scss";
import ScrollTrigger from "react-scroll-trigger";

const SectionImageComponent = ({ image, imageAlt, justifyContent }) => {
  const [isScrollIn, setIsScrollIn] = useState(false);

  return (
    <ScrollTrigger onEnter={() => setIsScrollIn(true)}>
      <div
        style={{
          display: "flex",
          justifyContent: justifyContent,
          visibility: isScrollIn ? "visible" : "hidden",
        }}>
        <img
          className={
            isScrollIn
              ? "section-image animate__animated animate__zoomIn animate__delay-0.8s"
              : "section-image"
          }
          src={image}
          alt={imageAlt}
        />
      </div>
    </ScrollTrigger>
  );
};

export default SectionImageComponent;
