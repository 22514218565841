import React from "react";
import "./EmailMarketing.scss";
import HeroSection from "../../common/HeroSection/HeroSection";
import PurpleSection from "../common/PurpleSection";
import WhiteSection from "../common/WhiteSection";
import ClientComments from "../../home-page/ClientComments";
import CalendlyComponent from "../../home-page/CalendlyComponent";
import FooterMain from "../../Layout/Foooter/FooterMain";
import emailMarketingInfo from "../../../utils/servicesInfo/email-marketing/emailMarketingInfo";
import LightPurpleSection from "../common/LightPurpleSection";
import sectionAnimation1 from "../../../assets/json-animations/email-marketing-animation-1.json";
import sectionAnimation2 from "../../../assets/json-animations/email-marketing-animation-2.json";
import heroAnimation from "../../../assets/json-animations/email-marketing-hero-animation.json";
const EmailMarketing = () => {
  return (
    <>
      <HeroSection
        className="content-marketing-hero-section"
        firstHeaderText={emailMarketingInfo.heroSection.firstHeaderText}
        secondHeaderText={emailMarketingInfo.heroSection.secondHeaderText}
        textArr={emailMarketingInfo.heroSection.textArr}
        paddingLeftImgCol="50px"
        heroAnimation={heroAnimation}
        btnTitle="Запитване"
        btnOnHover="on-hover-purple"
        textColor="section-text-color-purple"
        textAlign="left"
      />
      <PurpleSection
        sectionHeader={emailMarketingInfo.purpleSectionText.header}
        textArr={emailMarketingInfo.purpleSectionText.textArr}
        textColor="section-text-color-white"
        textAlign="left"
        sectionAnimation={sectionAnimation1}
      />
      <WhiteSection
        sectionHeader={emailMarketingInfo.whiteSectionText.header}
        textArr={emailMarketingInfo.whiteSectionText.textArr}
        textColor="section-text-color-purple"
        textAlign="left"
        sectionAnimation={sectionAnimation2}
      />
      <LightPurpleSection
        headerText={emailMarketingInfo.lightPurpleSection.header}
        headerAlign="center"
        headerColorType="section-header-purple"
      >
        <div className="tools-wrapper">
          {emailMarketingInfo.lightPurpleSection.logos.map((logo, index) => {
            return (
              <img
                className="tool-logo-img"
                alt=""
                src={logo}
                key={index + 1}
              />
            );
          })}
        </div>
      </LightPurpleSection>
      <ClientComments />
      <CalendlyComponent />
      <FooterMain />
    </>
  );
};

export default EmailMarketing;
