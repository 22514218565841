import React from "react";
import PortfolioTemplate from "../utils/PortfolioTemplate";
import portfolioClientInfo from "../../../../utils/portfolioInfo/portfolioClientInfo";

export default function JackotDecor() {
  return (
    <PortfolioTemplate
      mainHeaderText={portfolioClientInfo.jackot.mainHeaderText}
      mainImg={portfolioClientInfo.jackot.mainImg}
      mockupImg={portfolioClientInfo.jackot.mockupImg}
      clientData={portfolioClientInfo.jackot.clientData}
    />
  );
}
