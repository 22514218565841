import React from "react";
import FooterMain from "../../../Layout/Foooter/FooterMain";
import HeroHeader from "../../../common/HeadersComponents/HeroHeader";
import { Container, Row, Col } from "react-bootstrap";
import ServiceSectionHeader from "../../../common/HeadersComponents/ServiceSectionHeader";
import ServicePageParagraphs from "../../../common/ParagraphComponents/ServicePageParagraphs";
import "./PortfolioTemplate.scss";
import PortfolioProjectsSection from "../../PortfolioProjectsSection";

const PortfolioTemplate = ({
  mainHeaderText,
  mainImg,
  clientData,
  mockupImg,
  logo = true,
}) => {
  return (
    <div className="portfolio-client-wrapper">
      <Container>
        <Row>
          <Col>
            <HeroHeader
              headerText={mainHeaderText}
              colorType="dark"
              className="portfolio-client-header"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <img
              className="portfolio-info-main-img"
              src={mainImg}
              alt={mainHeaderText}
            />
          </Col>
        </Row>
        <Row>
          <Col xl="4">
            <div>
              {logo && (
                <img
                  className="portfolio-info-client-logo"
                  src={clientData.logo}
                  alt={`${mainHeaderText} logo`}
                />
              )}
              <div className="portolio-info-client-data-box-left">
                <h4 className="portfolio-info-client-data-header-left">Име:</h4>
                <p className="portfolio-info-client-data-paragraph-left">
                  {clientData.name}
                </p>
              </div>
              <div className="portolio-info-client-data-box-left">
                <h4 className="portfolio-info-client-data-header-left">
                  Уебсайт:
                </h4>
                <a
                  href={clientData.website}
                  className="portfolio-info-client-data-website-left">
                  {clientData.website}
                </a>
              </div>
              <div className="portolio-info-client-data-box-left">
                <h4 className="portfolio-info-client-data-header-left">
                  Година:
                </h4>
                <p className="portfolio-info-client-data-paragraph-left">
                  {clientData.year}
                </p>
              </div>
              <div className="portfolio-info-client-data-box-left">
                <h4 className="portfolio-info-client-data-header-left">
                  Категория:
                </h4>
                <p className="portfolio-info-client-data-paragraph-left">
                  {clientData.category}
                </p>
              </div>
            </div>
          </Col>
          <Col xl="8">
            <ServiceSectionHeader
              headerText={clientData.header}
              headerAlign={clientData}
              colorType="dark"
            />
            <ServicePageParagraphs
              textArr={clientData.textArr}
              textColor={clientData.textColor}
              textAlign="left"
            />
            <h3 className="portfolio-info-client-data-header">Услуги</h3>
            <ul className="portfolio-info-client-data-services">
              {clientData.services.map((service, index) => (
                <li key={index + 1}>{service}</li>
              ))}
            </ul>
          </Col>
        </Row>
      </Container>
      <div className="portfolio-info-client-mockup-img-wrapper">
        <img
          className="portfolio-info-client-mockup-img"
          src={mockupImg}
          alt={`${mainHeaderText} results`}
        />
      </div>
      <PortfolioProjectsSection />
      <FooterMain />
    </div>
  );
};

export default PortfolioTemplate;
