import LifeStoreImg from "../../assets/img/Lifestore-main.png";
import HerbamedicaImg from "../../assets/img/Herbamedica-main.png";
import DetoxCenter from "../../assets/img/DC-main.png";
import SanatioImg from "../../assets/img/Sanatio-main.png";
import CloudCardImg from "../../assets/img/cloudcart-main.png";
import BruteImg from "../../assets/img/brute-main.png";
import StariaChinarImg from "../../assets/img/staria-main.png";
import FreshHolydayImg from "../../assets/img/fresh-main.png";
import AvivImg from "../../assets/img/aviv-main.png";
import JacotImg from "../../assets/img/jakot-main.png";
import TiandeImg from "../../assets/img/sofiamytiande1.png";
import VideonabludenieImg from "../../assets/img/Videonabliudenie1.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faFileLines,
  faHandshake,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";

const portfolioInfo = {
  heroSection: {
    firstHeader: "Изграждаме дигитален облик,",
    secondHeader: "марки и преживявания",
    paragraphs: [
      "Симбиоза от творчество, стратегия и технология, основани на цифровите технологии и силата на човешкото изразяване. Вижте нашата работа в действие.",
    ],
  },
  portfolioPurpleSection: {
    header: "Индивидуален подход в маркетинг стратегията",
    paragraphs: [
      "Когато започваме работа с нов клиент, ние сме водени от мисията да помагаме на бизнеса за растеж и успешно позициониране онлайн преди конкурентите. Много от услугите ни могат да се приложат и за бизнеси с физически обекти, като на етап консултиране ще Ви представим предимствата на всяка от тях.",
      "Нашият екип от експерти има над 7 години опит в дигиталния маркетинг, управлението на профили в социални медии, създаването на SEO съдържание и рекламни кампании, графичен дизайн и други, които носят преимущества за най-малкия до най-големия бизнес. ",
      "За да предоставим ефективни услуги, винаги сме в крак с най-новите тенденции и технологии в дигиталния свят, които ни помагат да постигаме по-добри резултати за Вас чрез индивидуална стратегия.",
    ],
  },
  portfolioWhiteSection: {
    header: "Дигитални решения за всеки бизнес",
    paragraphs: [
      "Тъй като обхватът на вниманието на потребителите става все по-кратък, тактическият маркетинг става все по-важен. В този бързо променящ се свят има една константа и това е силата на последователния стратегически маркетинг за преминаване през хаоса.",
      "Нашият единен подход дава устойчиви резултати и се базира на индивидуалната стратегия, разработка и нейното изпълнение. Това ни отличава като екип на агенция за дигитален маркетинг Finest Copy - най-доброто от света на маркетинга! Партнираме си успешно с бизнеси в множество сектори от всякакъв вид и размер - над 10 различни индустрии, като за поддържане на отношенията с всеки клиент работи личен акаунт мениджър. Ето как го правим за текущите ни клиенти и партньори на В2С и В2B пазари:",
    ],
  },
  portfolioProjectSection: [
    { header: "Lifestore", image: LifeStoreImg, path: "/portfolio/lifeStore" },
    {
      header: "Herbamedica ",
      image: HerbamedicaImg,
      path: "/portfolio/Herbamedica",
    },
    {
      header: "Detox Center",
      image: DetoxCenter,
      path: "/portfolio/DetoxCenter",
    },
    { header: "Sanat.io", image: SanatioImg, path: "/portfolio/sanatio" },
    { header: "CloudCart", image: CloudCardImg, path: "/portfolio/CloudCart" },
    { header: "Brut(e)", image: BruteImg, path: "/portfolio/brute" },
    {
      header: "Стария Чинар ",
      image: StariaChinarImg,
      path: "/portfolio/stariqChinar",
    },
    {
      header: "Fresh Holiday ",
      image: FreshHolydayImg,
      path: "/portfolio/freshHoliday",
    },
    { header: "AVIV ", image: AvivImg, path: "/portfolio/aviv" },
    {
      header: "Jakot Decor ",
      image: JacotImg,
      path: "/portfolio/jackot-decor",
    },
    { header: "Sofia my Tiande", image: TiandeImg, path: "/portfolio/tiande" },
    {
      header: "Videonabliudenie ",
      image: VideonabludenieImg,
      path: "/portfolio/videonabludenie",
    },
  ],
  portfolioCounterSection: [
    {
      title: "Брой статии",
      maxCount: 471,
      icon: <FontAwesomeIcon icon={faFileLines} />,
    },
    {
      title: "Рекламни кампании",
      maxCount: 72,
      icon: <FontAwesomeIcon icon={faThumbsUp} />,
    },
    {
      title: "Клиенти",
      maxCount: 44,
      icon: <FontAwesomeIcon icon={faHandshake} />,
    },
    {
      title: "Бизнес сфери",
      maxCount: 13,
      icon: <FontAwesomeIcon icon={faBriefcase} />,
    },
  ],
};

export default portfolioInfo;
