import { Outlet, ScrollRestoration, useNavigation } from "react-router-dom";
import Navbar from "../components/Layout/navbar/Navbar";
import CookieConsent from "../components/CookieConsent/CookieConsent";

const RootLayout = () => {
  const navigation = useNavigation();

  return (
    <>
      <Navbar />
      {navigation.state === "loading" && <h2>Loading...</h2>}
      <CookieConsent />
      <Outlet />
      <ScrollRestoration
        getKey={(location, matches) => {
          return location.key;
        }}
      />
    </>
  );
};

export default RootLayout;
