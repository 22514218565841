import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./PortfolioProjectComponent.scss";

const PortfolioProjectComponent = ({ path, image, header }) => {
  const navigate = useNavigate();
  const [showSticker, setShowSticker] = useState(false);
  return (
    <Col xl="4" lg="6" onClick={() => navigate(path)}>
      <div
        className="portfolio-project-wrapper"
        onMouseEnter={() => setShowSticker(true)}
        onMouseLeave={() => setShowSticker(false)}>
        <div className="portfolio-project-img-wrapper">
          <img src={image} alt={header} />
          <div
            className={
              showSticker
                ? "portfolio-project-image-sticker-show"
                : "portfolio-project-image-sticker"
            }>
            <p>ВИЖ</p>
          </div>
        </div>
        <h3 className="portfolio-project-header">{header}</h3>
      </div>
    </Col>
  );
};

export default PortfolioProjectComponent;
