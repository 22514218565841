import React from "react";
import "./GoogleAdd.scss";
import HeroSection from "../../common/HeroSection/HeroSection";
import PurpleSection from "../common/PurpleSection";
import WhiteSection from "../common/WhiteSection";
import ClientComments from "../../home-page/ClientComments";
import CalendlyComponent from "../../home-page/CalendlyComponent";
import FooterMain from "../../Layout/Foooter/FooterMain";
import googleAddsInfo from "../../../utils/servicesInfo/google-adds/googleAdds";
import sectionImg from "../../../assets/img/google-adds-section-img.png";
import sectionAnimation from "../../../assets/json-animations/google-adds-white-section-animation.json";
import heroAnimation from "../../../assets/json-animations/google-hero-animation.json";
const GoogleAdd = () => {
  return (
    <>
      <HeroSection
        className="content-marketing-hero-section"
        firstHeaderText={googleAddsInfo.heroSection.firstHeaderText}
        secondHeaderText={googleAddsInfo.heroSection.secondHeaderText}
        textArr={googleAddsInfo.heroSection.textArr}
        paddingLeftImgCol="50px"
        heroAnimation={heroAnimation}
        btnTitle="Запитване"
        btnOnHover="on-hover-purple"
        textColor="section-text-color-purple"
        textAlign="left"
      />
      <PurpleSection
        sectionHeader={googleAddsInfo.purpleSectionText.header}
        textArr={googleAddsInfo.purpleSectionText.textArr}
        textColor="section-text-color-white"
        textAlign="left"
        sectionImg={sectionImg}
        imagePositionLeft="47%"
      />
      <WhiteSection
        sectionHeader={googleAddsInfo.whiteSectionText.header}
        textArr={googleAddsInfo.whiteSectionText.textArr}
        textColor="section-text-color-purple"
        textAlign="left"
        sectionAnimation={sectionAnimation}
      />
      <ClientComments />
      <CalendlyComponent />
      <FooterMain />
    </>
  );
};

export default GoogleAdd;
