import {
  faBullhorn,
  faFileLines,
  faGlobe,
  faListCheck,
  faMagnifyingGlass,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const copywritingInfo = {
  heroSection: {
    firstHeaderText: "Копирайтинг,",
    secondHeaderText: "който печели сърцата",
    textArr: [
      "Една дума може да каже хиляди неща, да предизвика безброй емоции и асоциации. Ето защо копирайтингът е толкова мощен инструмент в арсенала на дигиталния маркетинг. Но това не е единствената причина - добрият копирайтинг позволява на потребителите да намерят Вашия уебсайт или профили в социалните мрежи, за да направят това, в което сте ги убедили. Добрият копирайтинг убеждава и продава на точните хора, в точното място и най-подходящото време. ",
    ],
  },
  purpleSectionText: {
    header: "Копирайтинг - тук и сега",
    textArr: [
      "Онлайн потребителите нямат много свободно време – те искат отговори, продукти и решения. Точно сега. Копирайтингът може да им предостави точно това - подходящата информация за един онлайн бизнес, неговите актуални продукти и услуги. Това е тяхното първо впечатление и инструментът, който помага на потребителите да намерят това, от което се нуждаят бързо и лесно.",
      "Изключително важно е копирайтингът да бъде уникален за аудиторията, забележим и да следва бранд идентичността на марката. Всяка дума трябва да бъде избрана според потенциала си да въздейства и да влияе върху аудиторията. За да бъде копито или съдържанието ефективно, креативността и маркетинговата психология трябва да се слеят в едно. Именно синергията помежду им е това, което движи един бизнес напред.",
      ,
    ],
  },
  whiteSectionText: {
    header: "Копирайтърите ни са по-добри от копирайтърите Ви",
    textArr: [
      "Копирайтърите са специалисти по маркетинг, които познават (и се възползват) от силата на думите - или майсторлъка с думи, за да продават. Колко пари сте похарчили до момента за копирайтинг с ниско качество? Можете ли да продължите да инвестирате времето си в процеса на производство на съдържание, без да разбирате стъпките, които стоят зад него? ",
      "Спрете да създавате съдържание заради самото съдържание, започнете да създавате съдържание, което е стратегическо, подходящо и ангажиращо. От естествено, оптимизирано за ключови думи съдържание на уебсайта, до авторитетни статии за лидерство, ние от Finest Copy имаме експертния опит в над десет индустрии, за да създадем съдържанието, от което се нуждаят Вашите потребители",
      "Най-важната цел на копирайтинга е бизнесът, който представя, да бъде забелязан. А с подходящото послание и структура, ние можем да достигнем до всяка аудитория. Независимо дали става въпрос за еднократен проект за копирайтинг, за да подобрите уебсайта си, или търсите креативна стратегия за съдържание и PR кампании, ние можем да отговорим на тези търсения с повече от шест години практика и над 50 изпълнени проекта. ",
    ],
  },
  lightPurpleSection: {
    header: "Копирайтингът има много лица",
    cardsInfoArr: [
      {
        title: "Копи за уебсайт и целеви страници",
        text: "Уеб копи, което конвертира = продава. Вашият уебсайт е дигиталният вход към Вашия бизнес и първоначалното впечатление на Вашите клиенти е от съществено значение за задържането им, което след това да премине в конверсия. Най-често това е продажба на продукт или услуга.",
        iconCode: <FontAwesomeIcon icon={faGlobe} />,
      },
      {
        title: "Рекламен копирайтинг",
        text: "Копирайтърите на FC са специализирани в писането на ефективни и ориентирани към резултатите рекламни текстове. Дълбокото разбиране на маркетинговата психология, съчетано със способността да разбираме нюансите на индустрията, ни позволява да създаваме рекламен копирайтинг, който съчетава полезност и емоция за повече продажби.",
        iconCode: <FontAwesomeIcon icon={faThumbsUp} />,
      },

      {
        title: "Продуктови описания",
        text: "Вашият идеален клиент е идеален клиент и на някой друг, а борбата с конкуренцията днес е все по-трудна. Описанието на продукта е маркетинговото копи, което обяснява какво представлява той и защо си струва да бъде закупен. Има много SEО изисквания към такива описания, и ние знаем кои са те.",
        iconCode: <FontAwesomeIcon icon={faListCheck} />,
      },
      {
        title: "PR материали",
        text: "Професионално написаните прессъобщения привличат вниманието, което Вашият бизнес заслужава. Нашият екип разбира какво предизвиква интереса на новините и медиите, реализирайки правилната последователност от прес съобщения и PR новини. “Short and sweet” е само едно от правилата, а за другите - може да ни попитате.",
        iconCode: <FontAwesomeIcon icon={faBullhorn} />,
      },
      {
        title: "Съдържание за блог",
        text: "Уникалното и релевантно за потребителите блог съдържание е от ключово значение за стимулиране на органичния (неплатен) трафик. Нашите автори на съдържание планират и създават информативни, креативни, разяснителни и други базирани на данни SEO блог публикации на три езика - български, английски и немски език.",
        iconCode: <FontAwesomeIcon icon={faFileLines} />,
      },
      {
        title: "SEO съдържание",
        text: "Търсачките като Google дават приоритет на висококачествено съдържание, класирайки го по критериите четимост, мобилна оптимизация, ключови думи и синтаксис. 68% от онлайн преживяванията започват със запитване в търсачките, а SEO стимулира 1000%+ повече трафик от органичните социални медии. Сами преценете дали SEO съдържанието си заслужава.",
        iconCode: <FontAwesomeIcon icon={faMagnifyingGlass} />,
      },
    ],
  },
};

export default copywritingInfo;
