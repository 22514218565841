import React, { useState } from "react";
import "./WhiteSection.scss";
import { Col, Container, Row } from "react-bootstrap";
import ServicePageParagraphs from "../../common/ParagraphComponents/ServicePageParagraphs";
import Lottie from "lottie-react";
import ScrollTrigger from "react-scroll-trigger";

const WhiteSection = ({
  sectionHeader,
  textArr,
  sectionImg = false,
  textColor,
  textAlign,
  sectionAnimation = false,
  animationBottomPosition,
  animationRightPosition,
  btn = false,
}) => {
  const [isScrollIn, setIsScrollIn] = useState(false);

  return (
    <div>
      <section
        className="white-section"
        style={{ visibility: isScrollIn ? "visible" : "hidden" }}>
        <Container>
          <ScrollTrigger onEnter={() => setIsScrollIn(true)}>
            <Row className="white-section-row">
              <Col lg="6">
                <div
                  className={
                    isScrollIn
                      ? "animate__animated animate__fadeIn animate__delay-0.8s white-section-img-wrapper"
                      : "white-section-img-wrapper"
                  }>
                  {sectionImg && <img src={sectionImg} alt="/" />}
                  {sectionAnimation && (
                    <div
                      className="animation-wrapper"
                      style={{
                        bottom: animationBottomPosition,
                        right: animationRightPosition,
                      }}>
                      <Lottie
                        animationData={sectionAnimation}
                        loop={true}
                        autoPlay={true}
                      />
                    </div>
                  )}
                </div>
              </Col>
              <Col lg="6" className="text-column">
                <h2
                  className={
                    isScrollIn
                      ? "animate__animated animate__fadeInRight animate__delay-0.8s"
                      : ""
                  }>
                  {sectionHeader}
                </h2>
                <ServicePageParagraphs
                  textArr={textArr}
                  textColor={textColor}
                  textAlign={textAlign}
                  extraClass={
                    isScrollIn
                      ? "animate__animated animate__slideInRight animate__delay-0.8s"
                      : ""
                  }
                />
                {btn && (
                  <div>
                    <a href="/">Научи повече</a>
                  </div>
                )}
              </Col>
            </Row>
          </ScrollTrigger>
        </Container>
      </section>
    </div>
  );
};

export default WhiteSection;
