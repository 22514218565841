import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const CookiePolicy = () => {
  return (
    <Container style={{ marginTop: "100px" }}>
      <Row>
        <Col>
          <h2>Политика за бисквитките</h2>
          <p>
            Ние от FCDigitall полагаме усилия да осигурим максимално удобни и
            качествени услуги на посетителите на уебсайта си. За целта
            използваме бисквитки – малки текстови файлове, които се съхраняват
            на Вашите устройства, съобразно условията на Европейския закон за
            бисквитките (EU Cookie Law). Важно е да знаете, че те не събират
            лични данни, които ви идентифицират.
          </p>
          <h3>За какво използваме бисквитките?</h3>
          <p>
            Ние използваме бисквитките, за да анализираме поведението на
            потребителите на нашия сайт – какво се харесва, какво не, какво Вие
            намирате за полезно и какво не Ви е интересно. След това използваме
            тези данни, за да подобрим съдържанието и вида на сайта си. Така
            ставаме по-добри за Вас. Използваме два вида бисквитки – тези на
            сайта: функционални (необходими) бисквитки и такива, генерирани от
            Google Analytics.{" "}
          </p>
          <p>
            Google Analytics използва _ga cookie сега, но понякога използва и
            стари бисквитки като _utma, _utmb, _utmc, __utmt, _utmz и други.
            Подробна информация за използваните бисквитки може да намерите тук:
            https://developers.google.com ‌Повече за това как работят
            бисквитките и настройки ще откриете на https://allaboutcookies.org/
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default CookiePolicy;
