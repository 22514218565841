import React from "react";
import "./ErrorPage.scss";
import { Col, Container, Row } from "react-bootstrap";
import Lottie from "lottie-react";
import errorPageAnimation from "../../assets/json-animations/error-page-animation.json";
const ErrorPage = () => {
  return (
    <Container className="error-page-wrapper">
      <Row className="error-row">
        <Col xl="6" lg="6" md="12">
          <div className="error-page-img-wrapper">
            <Lottie
              loop={true}
              autoPlay={true}
              animationData={errorPageAnimation}
              className="error-page-animation"
            />
          </div>
        </Col>
        <Col xl="6" lg="6" md="12">
          <div className="error-page-text-wrapper">
            <h1>Нещо се обърка!</h1>
            <h1>Грешка 404...</h1>
            <p>
              Съобщението 404 Not Found е HTTP статус код, който уеб сървърът
              връща към уеб браузъра. Съобщението error 404 се получава при опит
              за достъпване на уеб страница или съдържание, което не е намерено
              на сървъра.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ErrorPage;
