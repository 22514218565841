import instagramLogo from "../../../assets/tools-logo/instagram-logo.png";
import hootSuiteLogo from "../../../assets/tools-logo/Hootsuite-Logo.png";
import sproutSocialLogo from "../../../assets/tools-logo/sprout-social-logo.png";
import bufferLogo from "../../../assets/tools-logo/Buffer-Logo.png";

const instagramAddInfo = {
  heroSection: {
    firstHeaderText: "Instagram",
    secondHeaderText: "реклама",
    textArr: [
      "Тъй като използването на социални медии продължава да се увеличава във всички демографски групи, маркетингът в социалните медии се превърна в ценна стратегия за бизнеса. Но тъй като предпочитанията на потребителите се променят, може да бъде трудно да сте в крак с най-добрите платформи за Вашата индустрия. Това важи в пълна сила за Instagram, където задължително трябва да имате фен база, и препоръчително - Instagram реклама.",
    ],
  },
  purpleSectionText: {
    header: "Потенциалът на Instagram като маркетинг канал",
    textArr: [
      "С огромен растеж през последното десетилетие и някои възходи и падения, днес Instagram има близо 2 милиарда активни потребители месечно. Числото е доста впечатляващо, като се има предвид постоянната конкуренция, пред която е изправен Instagram от новите платформи, включително Tik Tok и най-новата анонсирана през 2023 г. “Threads”. Но какво може да донесе на Вашия бизнес?",
      "За разлика от Facebook и Twitter, които започнаха като базирани на браузър сайтове, Instagram беше създаден като мобилно приложение. Според данните на eMarketer, мобилните потребители прекарват 88% от времето си в приложения, което прави Instagram естествена дестинация за мобилните потребители.",
      "Според Semrush, въз основа на общия трафик на уебсайтове, Instagram е един от 10-те най-посещавани уебсайта в света с общо 4,25 милиарда посещения на месец. Вашите потенциални клиенти също са там, особено ако са от Милениалите или поколението Z",
      "С почти 2 милиарда потребители месечно, Instagram е изключително популярен, особено сред по-младите. Тъй като Instagram има толкова много потребители с различни нужди и интереси, бизнеси от всякакъв размер и във всички индустрии могат да процъфтяват в платформата.",
      "Тъй като Instagram е силно визуална платформа, той е отличен маркетингов канал за продаване на иновативни, интересни и дизайнерски продукти и услуги. Индустрии като мода, заведения, туризъм, красота, домашен декор и обзавеждане, градинарство и събития се радват на огромен успех, включвайки Instagram реклама в своята маркетинг стратегия.",
    ],
  },
  whiteSectionText: {
    header: "Предимства на Instagram рекламата",
    textArr: [
      "Рекламите в Instagram са един от най-добрите начини за развитие на Вашия бизнес. Те са строго таргетирани, напълно персонализирани и достъпни за всеки с рекламен бюджет. Освен това, рекламите в Instagram са визуално достъпни и лесни за възприемане, покриват голям брой потребители и подкрепят създаването на поддръжници на марката.",
      "Ако досега сте правили нещо в Instagram, то знайте, че има много тънкости и правила, които постоянно се променят, и намаляват достигането на органичните постове. Маркетолозите, които се опитват да спечелят нова аудитория там, знаят, че рекламите играят важна роля по отношение най-вече на разпознаваемостта на марката.",
      "Освен това, Instagram е част от интегрираните решения на Meta и може да предложи достигане до повече потенциални клиенти, изграждане на лоялност и стимулиране на конверсиите. За постигането на тези цели е необходима стратегия - от опознаването на бизнеса, аудиториите и асортимента му, ние можем да управляваме Вашите профили в социални мрежи, като създаваме креативно съдържание, което ангажира аудиторията и превръща хората не просто в клиенти, а в поддръжници на бранда.",
      "Рекламирането в Instagram позволява достигане до нови клиенти чрез персонализиране по конкретни демографски критерии, детайлно проследяване на реакции, коментари и други важни потребителски данни за активността. Избирайки да имате присъствие в Instagram, може да повишите осведомеността за марката и да се свържете по-лесно с целевата си аудитория, изграждайки дългосрочни взаимоотношения.",
    ],
  },
  lightPurpleSection: {
    header: "Използвани технологии",
    logos: [instagramLogo, hootSuiteLogo, sproutSocialLogo, bufferLogo],
  },
};

export default instagramAddInfo;
