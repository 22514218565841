import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./StaffComponent.scss";

const StaffComponent = ({ staff }) => {
  return (
    <div className="staff-wrapper">
      <Container>
        <Row>
          {staff.slice(0, 2).map((person) => (
            <Col lg={6} md={12} key={person.name}>
              <div className="person-wrapper">
                <div className="person-img-wrapper">
                  <img
                    className="person-img"
                    src={person.image}
                    alt={person.name}
                  />
                </div>
                <h2 className="person-name">{person.name}</h2>
                <h3 className="person-role">{person.role}</h3>
                <p className="person-description">{person.description}</p>
              </div>
            </Col>
          ))}
        </Row>
        <Row>
          {staff.slice(2, 4).map((person) => (
            <Col lg={6} md={12} key={person.name}>
              <div className="person-wrapper">
                <div className="person-img-wrapper">
                  <img
                    className="person-img"
                    src={person.image}
                    alt={person.name}
                  />
                </div>
                <h2 className="person-name">{person.name}</h2>
                <h3 className="person-role">{person.role}</h3>
                <p className="person-description">{person.description}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default StaffComponent;
