import React, { useState } from "react";
import CounterComponent from "./CounterComponent";
import { Container, Row } from "react-bootstrap";
import portfolioInfo from "../../utils/portfolioInfo/portfolioInfo";
import ScrollTrigger from "react-scroll-trigger";
import "./CounterComponent.scss";

const CounterComponentSection = () => {
  const [counterOn, setCounterOn] = useState(false);
  return (
    <section className="counter-section-component">
      <ScrollTrigger
        onEnter={() => setCounterOn(true)}
        onExit={() => setCounterOn(false)}
      >
        <Container>
          <Row>
            {counterOn &&
              portfolioInfo.portfolioCounterSection.map((counter, index) => (
                <CounterComponent
                  title={counter.title}
                  maxCounterValue={counter.maxCount}
                  key={index + 1}
                  icon={counter.icon}
                />
              ))}
          </Row>
        </Container>
      </ScrollTrigger>
    </section>
  );
};

export default CounterComponentSection;
