import { useEffect, useState } from "react";
import "./CookieConsent.scss";
import { Link } from "react-router-dom";

const CookieConsent = () => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (sessionStorage.getItem("acceptCookies") === "true") {
      setShow(false);
    }
  }, []);

  const acceptCookies = () => {
    sessionStorage.setItem("acceptCookies", "true");
    setShow(false);
  };

  return (
    <>
      {show && (
        <div className="cookie">
          <div className="cookie-text">
            Нашият уебсайт използва бисквитки. Продължавайки напред, ние
            приемаме, че имаме Вашето разрешение и съгласие за разполагане на
            бисквитки, както е описано подробно в нашата Политика за бисквитки.
          </div>
          <div className="cookie-btn">
            <button className="cookie-btn-item" onClick={acceptCookies}>
              Съгласен съм
            </button>
            <Link className="cookie-btn-item" to="/cookie-policy">
              Виж повече
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default CookieConsent;
