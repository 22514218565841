import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import FooterMain from "../Layout/Foooter/FooterMain";
import "./ArticleComponent.scss";
import DOMPurify from "dompurify";
import { UserContext } from "../../UserContext/UserContext";
import axios from "axios";

const ArticleComponent = () => {
  const { id } = useParams();
  const [postInfo, setPostInfo] = useState(null);
  const { userId } = useContext(UserContext);
  const navigate = useNavigate();

  const htmlDecode = (content) => {
    let e = document.createElement("div");
    e.innerHTML = content;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  };

  useEffect(() => {
    fetch(`https://finest-copy-5c15d5533e67.herokuapp.com/post/${id}`).then(
      (response) => {
        response.json().then((postInfo) => {
          setPostInfo(postInfo);
        });
        console.log(postInfo);
      }
    );
  }, []);

  const deleteHandler = async () => {
    const response = await axios.delete(
      `https://finest-copy-5c15d5533e67.herokuapp.com/delete/${id}`
    );
    if (response.status === 200) {
      navigate("/blog");
    }
  };

  if (!postInfo) return "";
  return (
    <>
      <Container className="article-wrapper">
        <Row>
          <Col xl="12">
            <div className="article-img-wrapper">
              <img src={postInfo.image} alt="img" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xl="12">
            <h1 className="article-title">{postInfo.title}</h1>
          </Col>
        </Row>
        <Row>
          <Col xl="12">
            <div
              className="content"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(htmlDecode(postInfo.content)),
              }}
            />
          </Col>
        </Row>
        <div className="extra-buttons">
          {userId && (
            <div className="edit-btn-wrapper">
              <Link to={`/edit/${postInfo._id}`} className="edit-btn">
                Edit post
              </Link>
            </div>
          )}
          {userId && (
            <button onClick={deleteHandler} className="delete-btn">
              Delete
            </button>
          )}
        </div>
      </Container>
      <FooterMain />
    </>
  );
};

export default ArticleComponent;
