import React from "react";
import PortfolioTemplate from "../utils/PortfolioTemplate";
import portfolioClientInfo from "../../../../utils/portfolioInfo/portfolioClientInfo";

const DetoxCenter = () => {
  return (
    <PortfolioTemplate
      mainHeaderText={portfolioClientInfo.detoxCenter.mainHeaderText}
      mainImg={portfolioClientInfo.detoxCenter.mainImg}
      mockupImg={portfolioClientInfo.detoxCenter.mockupImg}
      clientData={portfolioClientInfo.detoxCenter.clientData}
    />
  );
};

export default DetoxCenter;
