import { faOctopusDeploy } from "@fortawesome/free-brands-svg-icons";
import {
  faEye,
  faFaceSmile,
  faGem,
  faInfinity,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ourPrinciplesInfo = [
  {
    title: "Почтеност",
    infoText:
      "Демонстриране на честност, прозрачност и етично поведение във всички действия и взети решения.",
    iconCode: <FontAwesomeIcon icon={faFaceSmile} />,
    id: Math.floor(Math.random() * 486748936312),
  },
  {
    title: "Прозрачност",
    infoText:
      "Яснота в комуникацията на всеки етап - преговори, сключване на партньорство и съвместна работа.",
    iconCode: <FontAwesomeIcon icon={faEye} />,
    id: Math.floor(Math.random() * 486748936312),
  },
  {
    title: "Адаптивност",
    infoText:
      "Приспособяване  към промените и бързо реагиране на нуждите и предизвикателствата на пазара.",
    iconCode: <FontAwesomeIcon icon={faInfinity} />,
    id: Math.floor(Math.random() * 486748936312),
  },
  {
    title: "Устойчивост",
    infoText:
      "Възприемане на отговорни практики и следване на ясен процес за управление на проекти.",
    iconCode: <FontAwesomeIcon icon={faGem} />,
    id: Math.floor(Math.random() * 486748936312),
  },
];

export default ourPrinciplesInfo;
