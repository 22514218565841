import React from "react";
import "./ParagraphStyles.scss";

const ServicePageParagraphs = ({
  textArr,
  textColor,
  textAlign,
  extraClass,
}) => {
  return (
    <>
      {textArr.map((paragraph, index) => (
        <p
          key={index + 1}
          className={`${textAlign}  ${textColor} ${extraClass} animate__animated animate__fadeInLeft animate__delay-0.8s`}
        >
          {paragraph}
        </p>
      ))}
    </>
  );
};

export default ServicePageParagraphs;
