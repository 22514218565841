import React, { useState } from "react";
import "./PurpleSection.scss";
import { Col, Container, Row } from "react-bootstrap";
import ServicePageParagraphs from "../../common/ParagraphComponents/ServicePageParagraphs";
import Lottie from "lottie-react";
import ScrollTrigger from "react-scroll-trigger";

const PurpleSection = ({
  sectionHeader,
  textArr,
  sectionImg,
  imagePositionLeft,
  textColor,
  textAlign,
  sectionAnimation = false,
  animationBottomPosition,
  animationRightPosition,
  btn = false,
}) => {
  const [isScrollIn, setIsScrollIn] = useState(false);
  return (
    <section
      className="purple-section"
      style={{ visibility: isScrollIn ? "visible" : "hidden" }}>
      <Container>
        <ScrollTrigger onEnter={() => setIsScrollIn(true)}>
          <Row className="purple-section-row">
            <Col lg="6" className="text-column">
              <h2
                className={
                  isScrollIn
                    ? "animate__animated animate__fadeInLeft animate__delay-0.8s"
                    : ""
                }>
                {sectionHeader}
              </h2>
              <ServicePageParagraphs
                textArr={textArr}
                textColor={textColor}
                textAlign={textAlign}
                extraClass={
                  isScrollIn
                    ? "animate__animated animate__slideInLeft animate__delay-0.8s"
                    : ""
                }
              />
              {btn && (
                <div>
                  <a href="/">Научи повече</a>
                </div>
              )}
            </Col>
            <Col lg="6">
              <div
                className={
                  isScrollIn
                    ? "purple-section-img-wrapper animate__animated animate__jackInTheBox animate__delay-0.8s"
                    : "purple-section-img-wrapper"
                }>
                {sectionImg && (
                  <img
                    src={sectionImg}
                    alt="/"
                    style={{ left: imagePositionLeft }}
                  />
                )}
                {sectionAnimation && (
                  <div
                    className="animation-wrapper"
                    style={{
                      bottom: animationBottomPosition,
                      right: animationRightPosition,
                    }}>
                    <Lottie
                      animationData={sectionAnimation}
                      loop={true}
                      autoPlay={true}
                    />
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </ScrollTrigger>
      </Container>
    </section>
  );
};

export default PurpleSection;
