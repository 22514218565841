import contentMarketingImg from "../../assets/img/copywriting-hero-img.png";
import seoImg from "../../assets/img/seo-page-hero-img1.png";
import fbInstagramImg from "../../assets/img/fb-add-section-img.png";
import googleAddsImg from "../../assets/img/google-adds-section-img.png";
import graphicDesignImg from "../../assets/img/graphic-design-section-img.png";
import webDevImg from "../../assets/img/web-dev-hero-img2.png";
import emailMarketingImg from "../../assets/img/email-marketing-hero-img1.png";

const servicesMain = {
  heroSection: {
    firstHeader: "Дигитални решения за",
    secondHeader: "Всеки бизнес",
    paragraphs: [
      "Работим с Вас, за да опознаем личността на Вашите клиенти и да проучим Вашия пазар, създавайки печеливша дигитална маркетингова стратегия, която се развива заедно с Вашия бизнес. Предлагаме на нашите клиенти интегриран подход - не само уебсайт или само присъствие в социални мрежи, а цялостна концепция за дигитален образ. ",
      "Нашите широкообхватни услуги включват дигитална стратегия, маркетинг чрез съдържание и копирайтинг, дизайн и разработка на уебсайт, многоканален дигитален маркетинг и ремаркетинг на аудиторията чрез имейл, маркетинг в търсачки (SEM), маркетинг в социални медии (SMM), видео, платени медии и PR кампании.",
    ],
  },
  marketingStrategy: {
    header: "Процес на създаване на цялостна маркетинг стратегия",
    paragraphs: [
      "Започвайки взаимоотношенията си със стратегия, ние изграждаме разбиране за Вашия екип и начина, по който работите с клиенти. Ще анализираме Вашето текущо положение на пазара, за да начертаем правилния път за съвместната ни работа, наличните дигитални активи и информация. След това ще проектираме и внедрим подходящите персонализирани решения, за да постигнем набелязаните цели.",
      "Нашата роля е да популяризираме бизнеса Ви, да изградим ангажираност с Вашите клиенти и да осигурим повече продажби. Всяко начинание и кампания, които създаваме, се основават на персонализирана фуния за продажби, която разработваме в сътрудничество с усилията на нашите клиенти.",
    ],
  },
  marketingStrategyDevelopmentSteps: {
    header: "Стъпки в разработването на маркетинг стратегия",
    paragraphs: [
      "За максимално ползотворен и печеливш процес за двете страни, вървим  заедно по следните стъпки в разработването на маркетинг стратегия:",
      "Анализ на текущото състояние на маркетинга - Преглед на съществуващото съдържание и определяне на цели, приоритети и грешки. Анализ на текущи дигитални активи на бранда.",
      "Концепция и Дизайн - Творческа концепция, дизайн, разработка, адаптиране.",
      "Създаване на основно съдържание - Създаване на висококачествени дигитални активи, които отговарят на дефинираната стратегия за съдържание.",
      "Подготовка на съдържание - Брейнсторминг на идеи, подготовка на активи според времевата рамка и въвеждане на маркетинг календар.",
      "Публикуване на съдържание - Настройки в платформите за публикуване, разпространение и качване в различните маркетингови канали по график.",
      "Измерване на ефекта - Проследяване на KPI метрики и генериране на доклади.",
      "Съхранение на съдържанието - Безопасно и сигурно съхранение на  създадените дигитални активи.",
    ],
  },
  allServices: [
    {
      header: "Маркетинг чрез съдържание и копирайтинг",
      paragraphs: [
        "Маркетингът на съдържанието е изкуството да общувате с Вашата целева аудитория без необходимост от директна реклама. Създаваме и споделяме ценно и последователно свързано съдържание, което привлича и задържа вниманието на ясно дефинирана аудитория.",
        "Ефективният копирайтинг също е от решаващо значение за успеха на всяка маркетингова кампания. Добре разработеното копи има основна цел да продава, но служи и за увеличаване на ангажираността, продажбите и установяване авторитет на марката.",
      ],
      img: contentMarketingImg,
      btnPath: "/services/content-marketing",
    },
    {
      header: "SEO оптимизация",
      paragraphs: [
        "SEO (оптимизацията за търсачки) е най-силното оръжие за подобряване на онлайн видимостта на един уебсайт, осигурявайки по-високи позиции в SERP. Това ще Ви помогне да привлечете повече потенциални клиенти и да увеличите продажбите, резервациите или запитванията, направени онлайн.",
      ],
      img: seoImg,
      btnPath: "/services/seo-optimization",
    },
    {
      header: "Facebook и Instagram реклама",
      paragraphs: [
        "Чрез Facebook реклама може да достигнете милиони потребители в социалната мрежа. Ние предлагаме персонализирани кампании, базирани на Вашите бизнес цели, като се фокусираме върху взаимодействието и конверсиите, за да увеличим видимостта на бранда и продажбите.",
        "Instagram рекламата е идеалната услуга за достигане до младата аудитория, която е активна в социалната мрежа. Ние създаваме кампании с изцяло персонализирано съдържание, което привлича вниманието на потенциалните клиенти и увеличава видимостта на бранда онлайн. ",
      ],
      img: fbInstagramImg,
      btnPath: "/services/facebookAdd",
    },
    {
      header: "Google реклама",
      paragraphs: [
        "Google рекламата помага да бъдете на видимо място, когато потребителите търсят в Google продукти или услуги, подобни на Вашите. Като част от стратегията селектираме ключови думи и провеждаме анализ на резултатите, за да осигурим висококачествен трафик, който се превръща в реални продажби.",
      ],
      img: googleAddsImg,
      btnPath: "/services/googleAdd",
    },
    {
      header: "Графичен дизайн",
      paragraphs: [
        "Услугите за графичен дизайн използват данни и визуални елементи, за да рационализират комуникацията и лесно да предадат Вашето съобщение в подходящия формат. Независимо дали имате нужда от нестандартна илюстрация, красив печатен дизайн или завладяващи дигитални маркетинг активи, графичните дизайнери на Finest Copy ще реализират Вашите идеи.",
      ],
      img: graphicDesignImg,
      btnPath: "/services/graphicDesign",
    },
    {
      header: "Изработка на уебсайт",
      paragraphs: [
        "В днешния дигитален пейзаж фирмите се нуждаят не само от визуално привлекателен уебсайт. Необходимо е да се имплементират стратегически и ориентирани към потребителя дизайнерски решения, които стимулират растежа, подобряват ангажираността на потребителите и установяват силно онлайн присъствие. Ние ще създадем уебсайт с модерна съвременна визия, лесен за ползване на всички устройства и работещ за привличане на посетители и конвертиране на трафика в продажби.",
      ],
      img: webDevImg,
      btnPath: "/services/webDevelopment",
    },
    {
      header: "Имейл маркетинг",
      paragraphs: [
        "Изпращането на имейл е нещо, което правим ежедневно. Изпращаме имейли на приятели, колеги и клиенти. Повечето потребители на електронна поща проверяват имейлите си неколкократно всеки ден, което прави имейл маркетинга един от най-добрите канали за развитие на Вашия бизнес.",
        "Има много ползи от имейл маркетинга за един бизнес, от повишаване на осведомеността за марката, до изграждане на по-здрави връзки с клиентите и стимулиране на продажбите персонализирани имейли. Ако искате успешно да рекламирате бизнеса си, трябва да включите имейл маркетинга във Вашата дигитална стратегия.",
      ],
      img: emailMarketingImg,
      btnPath: "/services/emailMarketing",
    },
  ],
};

export default servicesMain;
