import React, { useState } from "react";
import "./WhyUsSection.scss";
import { Col, Container, Row } from "react-bootstrap";
import ServiceSectionHeader from "../../common/HeadersComponents/ServiceSectionHeader";
import whyUsInfo, { whyUsForAboutPage } from "../../../utils/whyUsInfoCards";
import PrimaryCardComponent from "./primaryCardComponent/PrimaryCardComponent";
import ScrollTrigger from "react-scroll-trigger";

const WhyUsSection = ({ forAboutPage }) => {
  const [isScrollIn, setIsScrollIn] = useState(false);
  return (
    <section
      className="why-us-wrapper"
      style={{ visibility: isScrollIn ? "visible" : "hidden" }}>
      <ScrollTrigger onEnter={() => setIsScrollIn(true)}>
        <Container>
          <Row
            className={
              isScrollIn
                ? "animate__animated animate__zoomIn animate__delay-0.8s"
                : ""
            }>
            <ServiceSectionHeader
              headerAlign="center"
              colorType="section-header-purple center"
              headerText="Защо ние сме по-различни?"
            />
            {forAboutPage
              ? whyUsForAboutPage.map((element, index) => (
                  <Col xxl="4" xl="6" lg="12" key={index + 1}>
                    <PrimaryCardComponent
                      cardHeader={element.title}
                      cardIcon={element.iconCode}
                      key={index + 1}
                      cardText={element.infoText}
                      extraClass="about-us-primary-cards"
                    />
                  </Col>
                ))
              : whyUsInfo.map((element, index) => (
                  <Col xxl="4" xl="6" lg="6" md="12" key={index + 1}>
                    <PrimaryCardComponent
                      cardHeader={element.title}
                      cardIcon={element.iconCode}
                      key={index + 1}
                      cardText={element.infoText}
                    />
                  </Col>
                ))}
          </Row>
        </Container>
      </ScrollTrigger>
    </section>
  );
};

export default WhyUsSection;
