import React from "react";
import "./AboutPage.scss";
import HeroSection from "../common/HeroSection/HeroSection";
import WhyUsSection from "../servicec-page/common/WhyUsSection";
import ClientComments from "../home-page/ClientComments";
import aboutUs from "../../utils/aboutUsInfo/aboutUsInfo";
import FooterMain from "../Layout/Foooter/FooterMain";
import OurPrinciplesComponent from "./OurPrinciplesComponent";
import CalendlyComponent from "../home-page/CalendlyComponent";
import heroAnimation from "../../assets/json-animations/about-us-hero-animation.json";
import StaffComponent from "./StaffComponent";
import staffInfo from "../../utils/aboutUsInfo/staffInfo";

const AboutPage = () => {
  return (
    <>
      <HeroSection
        className="content-marketing-hero-section"
        firstHeaderText={aboutUs.heroSection.firstHeaderText}
        secondHeaderText={aboutUs.heroSection.secondHeaderText}
        textArr={aboutUs.heroSection.textArr}
        paddingLeftImgCol="50px"
        heroAnimation={heroAnimation}
        btnTitle="Запитване"
        btnOnHover="on-hover-purple"
        textColor="section-text-color-purple"
        textAlign="left"
      />
      <StaffComponent staff={staffInfo} />
      <WhyUsSection forAboutPage={true} />
      <OurPrinciplesComponent />
      <ClientComments />
      <CalendlyComponent />
      <FooterMain />
    </>
  );
};

export default AboutPage;
