import React from "react";
import "./InstagramAdd.scss";
import HeroSection from "../../common/HeroSection/HeroSection";
import PurpleSection from "../common/PurpleSection";
import WhiteSection from "../common/WhiteSection";
import ClientComments from "../../home-page/ClientComments";
import CalendlyComponent from "../../home-page/CalendlyComponent";
import FooterMain from "../../Layout/Foooter/FooterMain";
import instagramAddInfo from "../../../utils/servicesInfo/instagram-adds/instagramAddsInfo";
import LightPurpleSection from "../common/LightPurpleSection";
import sectionAnimation from "../../../assets/json-animations/instagram-purple-section-animation.json";
import sectionImg from "../../../assets/img/instagram-adds-section-img.png";
import heroAnimation from "../../../assets/json-animations/fbAdds-white-section-animation.json";
const InstagramAdd = () => {
  return (
    <>
      <HeroSection
        className="content-marketing-hero-section"
        firstHeaderText={instagramAddInfo.heroSection.firstHeaderText}
        secondHeaderText={instagramAddInfo.heroSection.secondHeaderText}
        textArr={instagramAddInfo.heroSection.textArr}
        paddingLeftImgCol="50px"
        heroAnimation={heroAnimation}
        btnTitle="Запитване"
        btnOnHover="on-hover-purple"
        textColor="section-text-color-purple"
        textAlign="left"
      />
      <PurpleSection
        sectionHeader={instagramAddInfo.purpleSectionText.header}
        textArr={instagramAddInfo.purpleSectionText.textArr}
        textColor="section-text-color-white"
        textAlign="left"
        sectionAnimation={sectionAnimation}
      />
      <WhiteSection
        sectionHeader={instagramAddInfo.whiteSectionText.header}
        textArr={instagramAddInfo.whiteSectionText.textArr}
        textColor="section-text-color-purple"
        textAlign="left"
        sectionImg={sectionImg}
      />
      <LightPurpleSection
        headerText={instagramAddInfo.lightPurpleSection.header}
        headerAlign="center"
        headerColorType="section-header-purple"
      >
        <div className="tools-wrapper">
          {instagramAddInfo.lightPurpleSection.logos.map((logo, index) => {
            return <img className="tool-logo-img" src={logo} key={index + 1} />;
          })}
        </div>
      </LightPurpleSection>
      <ClientComments />
      <CalendlyComponent />
      <FooterMain />
    </>
  );
};

export default InstagramAdd;
