import React from "react";
import PortfolioTemplate from "../utils/PortfolioTemplate";
import portfolioClientInfo from "../../../../utils/portfolioInfo/portfolioClientInfo";

const LifeStore = () => {
  return (
    <PortfolioTemplate
      mainHeaderText={portfolioClientInfo.lifeStore.mainHeaderText}
      mainImg={portfolioClientInfo.lifeStore.mainImg}
      mockupImg={portfolioClientInfo.lifeStore.mockupImg}
      clientData={portfolioClientInfo.lifeStore.clientData}
      logo={false}
    />
  );
};

export default LifeStore;
