import serpstatLogo from "../../../assets/tools-logo/serpstat-logo.png";
import semrushLogo from "../../../assets/tools-logo/Semrush-logo.png";
import ahrefsLogo from "../../../assets/tools-logo/ahrefs-logo.png";
import googleTrendsLogo from "../../../assets/tools-logo/google-trends-logo.png";
import rankMathLogo from "../../../assets/tools-logo/rankmath-logo.png";
import googleSearchConsoleLogo from "../../../assets/tools-logo/google-search-console-logo.png";
import googleAnalyticsLogo from "../../../assets/tools-logo/google-analytics-logo.png";
import chatGPTLogo from "../../../assets/tools-logo/chatgpt-logo.png";
import teamGPTLogo from "../../../assets/tools-logo/teamgpt-logo.png";

const seoInfo = {
  heroSection: {
    firstHeaderText: "SEO оптимизация",
    secondHeaderText: "За търсачки",
    textArr: [
      "SEO (или оптимизация за търсачки) е едно от основните решения за популяризиране на един бизнес в Интернет. Със SEO можете лесно да увеличите трафика - посещенията на уебсайта си и да подобрите класирането на страниците му в резултатите (SERP) за определени ключови думи.",
    ],
  },
  purpleSectionText: {
    header: " SEO оптимизацията като двигател на продажби",
    textArr: [
      "Защо това е важно? Причината е следната: тъй като много потенциални клиенти сърфират в мрежата в търсене на продукти и услуги, от които се нуждаят или се интересуват, като използват търсачките за тази цел – особено мастодонта Google. Точно затова първата му страница е най-очевидното място за популяризиране на вашия бизнес и придобиване на нови клиенти. А и това е пространството, в което скоро ще се появят Generative AI решенията на същата търсачка, точно над резултатите от търсенето.",
      "Ако трябва да запомните едно нещо за оптимизацията за търсачки, то е, че SEO осигурява голяма възвръщаемост на инвестицията, ако се изпълнява дългосрочно и ефективно. Оптимизацията за търсачки не изисква скъпи онлайн реклами, наддаване за ключови думи или необходимост да изпреварвате конкурентите си, макар че може да се постигне последното по ключови думи. ",
    ],
  },
  whiteSectionText: {
    header: "SEO оптимизацията е дългосрочна стратегия за успех",
    textArr: [
      "SEO оптимизацията изисква ежедневни и дългосрочни стъпки, изпълнявани стратегически, за подобряване видимостта на вашия уебсайт, увеличаване на броя на посетителите на уебсайта, по-високо класиране в SERP, изграждане на връзки и други SEO практики, които прилагаме в нашата работа. Всички те са съобразени с общоизвестните Google Search Essentials и синхронизирани с над 200 ранкинг сигнала, които имат ключово значение за класирането на всеки уебсайт и страниците му, включително за вашия.",
      "On-Page SEO, Off-Page SEO, Техническо SEO - искате ли да научите как ще помогнат на бизнеса ви да продава повече?",
    ],
  },
  lightPurpleSection: {
    header: "Използвани технологии",
    logos: [
      serpstatLogo,
      semrushLogo,
      ahrefsLogo,
      rankMathLogo,
      googleTrendsLogo,
      googleSearchConsoleLogo,
      googleAnalyticsLogo,
      chatGPTLogo,
      teamGPTLogo,
    ],
  },
};

export default seoInfo;
