import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./WhyUsSection.scss";
import flatIcon1 from "../../assets/img/typewriter.png";
import flatIcon2 from "../../assets/img/rocket.png";
import flatIcon3 from "../../assets/img/bar-chart.png";
import ScrollTrigger from "react-scroll-trigger";
import "animate.css";

const WhyUsSection = () => {
  const [isScrollIn, setIsScrollIn] = useState(false);
  const [isScrollInSectionTwo, setIsScrollInSectionTwo] = useState(false);
  const [isScrollInSectionThree, setIsScrollInSectionThree] = useState(false);
  return (
    <div className="why-us">
      <Container>
        <Row>
          <ScrollTrigger onEnter={() => setIsScrollIn(true)}>
            <Col>
              <h2
                className={
                  isScrollIn
                    ? "why-us-header animate__animated animate__fadeInDown animate__delay-0.8s"
                    : "why-us-header"
                }>
                представяне
              </h2>
            </Col>
          </ScrollTrigger>
        </Row>
        <ScrollTrigger onEnter={() => setIsScrollIn(true)}>
          <Row>
            <Col
              lg="6"
              md="12"
              sm="12"
              xs="12"
              className={
                isScrollIn
                  ? "animate__animated animate__fadeInLeft animate__delay-0.8s"
                  : ""
              }>
              <h3 className="why-us-h">Кои сме ние?</h3>
              <p className="why-us-p">
                Добре дошли на уебсайта на агенция Finest Copy, където “живеем”
                и “дишаме” дигитален маркетинг. Нашите основни ценности като
                мотивация, непрестанно развитие, солиден опит и почтеност са
                това, което ни ръководи в нашата мисия да предоставяме
                изключителни услуги на нашите клиенти в над 10 индустрии. <br />{" "}
                Вярваме, че уважението и работата в екип са от съществено
                значение за успеха. Всеки от нас е в крак с най-новите
                технологични постижения, проправяйки общия ни път в индустрията.
                Постоянното ни усъвършенстване е доказателство за отдадеността
                на нашите клиенти, предоставяйки им най-добрите услуги, които са
                съобразени с техните уникални бизнес нужди.
                <br /> Във Finest Copy винаги сме готови да поемем ново
                предизвикателство и да помогнем на нашите клиенти да постигнат
                целите си с най-подходящите маркетинг инструменти за тях.
                <br />
              </p>
            </Col>
            <Col className="img-wrapper" lg="6" md="12" sm="12" xs="12">
              <div
                className={
                  isScrollIn
                    ? "img-box animate__animated animate__zoomIn animate__delay-0.8s"
                    : "img-box "
                }>
                <img className="flat-icons" src={flatIcon1} alt="partnership" />
              </div>
            </Col>
          </Row>
        </ScrollTrigger>
        <ScrollTrigger onEnter={() => setIsScrollInSectionTwo(true)}>
          <Row>
            <Col
              className="img-wrapper"
              xl={{ order: 7 }}
              lg="6"
              md={{ order: 1 }}
              sm={{ order: 1 }}
              xs={{ order: 1 }}>
              <div
                className={
                  isScrollInSectionTwo
                    ? "img-box animate__animated animate__zoomIn animate__delay-0.8s"
                    : "img-box "
                }>
                <img className="flat-icons" src={flatIcon2} alt="partnership" />
              </div>
            </Col>
            <Col
              xl={{ order: 1 }}
              lg="6"
              md={{ order: 7 }}
              sm={{ order: 7 }}
              xs={{ order: 7 }}
              className={
                isScrollInSectionTwo
                  ? "animate__animated animate__fadeInRight animate__delay-0.8s"
                  : ""
              }>
              <h3 className="why-us-h">Защо да изберете нас?</h3>
              <p className="why-us-p">
                Във Finest Copy нашите основни ценности ръководят всичко, което
                правим.
                <br /> Ние се ангажираме да предоставяме изключителни услуги и
                опит на всички наши клиенти. Уважението е в челните редици на
                нашата фирмена култура и ние се стремим да създадем приобщаваща
                и приятна работна среда, където всеки се чувства ценен,
                мотивиран и способен да допринесе за нашия колективен успех.
                <br /> Стремим се да поддържаме среда, която насърчава
                иновативното мислене, сътрудничеството и работата в екип. Ние
                вярваме, че приемайки различни идеи, можем да намерим повече
                начини да постигнем целите си и да спечелим от екипната работа.
                Ние поставяме честността, надеждността и почтеността на преден
                план в нашите решения. Нашият ангажимент да правим това, което е
                правилно, се отразява във всичко, което създаваме, и се стремим
                да спечелим доверието както на нашите колеги, така и на
                клиентите и конкурентите.
                <br /> Във Finest Copy ние гледаме един на друг като на част от
                един и същи екип, като се стремим да предоставяме най-добрите
                резултати за нашите клиенти всеки ден. Въпреки че индивидуалното
                представяне е важно, ние вярваме, че работата заедно като екип е
                ключът към нашия - и вашия - колективен успех.
              </p>
            </Col>
          </Row>
        </ScrollTrigger>
        <ScrollTrigger onEnter={() => setIsScrollInSectionThree(true)}>
          <Row>
            <Col
              lg="6"
              md="12"
              sm="12"
              xs="12"
              className={
                isScrollInSectionThree
                  ? "animate__animated animate__fadeInLeft animate__delay-0.8s"
                  : ""
              }>
              <h3 className="why-us-h">
                Стратегически дигитални решения за всеки бизнес
              </h3>
              <p className="why-us-p">
                Нашият екип от креативни професионалисти работи в тясно
                сътрудничество, за да разбере точно посланията, ценностите и
                гласа на Вашата марка. Ние отделяме време, за да опознаем всяка
                целева аудитория и да създадем съдържание, което резонира с тях.
                Всяко съдържание, което създаваме, е уникално и оптимизирано за
                максимална ангажираност. <br />В днешния забързан дигитален свят
                бизнесът не може да си позволи да пренебрегне силата на
                дигиталния маркетинг. Това е рентабилен и измерим начин да се
                свържете с потенциални клиенти, да изградите лоялност към
                марката и да стимулирате растежа на продажбите и популярността.
                С персонализирана дигитална маркетингова стратегия ще начертаем
                и извървим пътя към повече продажби и популярност за Вас.
                Започвайки взаимоотношенията си със стратегия, ние изграждаме
                разбиране за Вашия екип и начина, по който работите с клиенти.
                Ще анализираме Вашето текущо положение на пазара и дигитални
                активи, за да начертаем правилния път за съвместна работа. След
                това ще проектираме и внедрим множество персонализирани решения,
                за да постигнем набелязаните цели.
                <br /> Всяка маркетинг или продажбена стратегия, които
                създаваме, се основават на персонализирана фуния за продажби,
                която разработваме в сътрудничество с усилията на нашите
                клиенти.
              </p>
            </Col>
            <Col className="img-wrapper" lg="6" md="12" sm="12" xs="12">
              <div
                className={
                  isScrollInSectionThree
                    ? "img-box animate__animated animate__zoomIn animate__delay-0.8s"
                    : "img-box "
                }>
                <img className="flat-icons" src={flatIcon3} alt="partnership" />
              </div>
            </Col>
          </Row>
        </ScrollTrigger>
      </Container>
    </div>
  );
};

export default WhyUsSection;
