import React from "react";
import "./WebDevelopment.scss";
import HeroSection from "../../common/HeroSection/HeroSection";
import PurpleSection from "../common/PurpleSection";
import WhiteSection from "../common/WhiteSection";
import ClientComments from "../../home-page/ClientComments";
import CalendlyComponent from "../../home-page/CalendlyComponent";
import FooterMain from "../../Layout/Foooter/FooterMain";
import heroImg1 from "../../../assets/img/web-dev-hero-img1.png";
import heroImg2 from "../../../assets/img/web-dev-hero-img2.png";
import LightPurpleSection from "../common/LightPurpleSection";
import webDevInfo from "../../../utils/servicesInfo/web-dev/webDevInfo";
import sectionImg from "../../../assets/img/web-dev-section-img.png";
import sectionAnimation from "../../../assets/json-animations/web-dev-section-animation.json";
import heroAnimation from "../../../assets/json-animations/web-dev-hero-animation.json";
const WebDevelopment = () => {
  return (
    <>
      <HeroSection
        className="content-marketing-hero-section"
        firstHeaderText={webDevInfo.heroSection.firstHeaderText}
        secondHeaderText={webDevInfo.heroSection.secondHeaderText}
        textArr={webDevInfo.heroSection.textArr}
        paddingLeftImgCol="50px"
        heroAnimation={heroAnimation}
        btnTitle="Запитване"
        btnOnHover="on-hover-purple"
        textColor="section-text-color-purple"
        textAlign="left"
      />
      <PurpleSection
        sectionHeader={webDevInfo.purpleSectionText.header}
        textArr={webDevInfo.purpleSectionText.textArr}
        textColor="section-text-color-white"
        textAlign="left"
        sectionImg={sectionImg}
        imagePositionLeft="47%"
      />
      <WhiteSection
        sectionHeader={webDevInfo.whiteSectionText.header}
        textArr={webDevInfo.whiteSectionText.textArr}
        textColor="section-text-color-purple"
        textAlign="left"
        sectionAnimation={sectionAnimation}
      />
      <LightPurpleSection
        headerText={webDevInfo.lightPurpleSection.header}
        headerAlign="center"
        headerColorType="section-header-purple"
      >
        <div className="tools-wrapper">
          {webDevInfo.lightPurpleSection.logos.map((logo, index) => {
            return <img className="tool-logo-img" src={logo} key={index + 1} />;
          })}
        </div>
      </LightPurpleSection>
      <ClientComments />
      <CalendlyComponent />
      <FooterMain />
    </>
  );
};

export default WebDevelopment;
