import React from "react";
import "./PrivacyPolicy.scss";
import { Col, Container, Row } from "react-bootstrap";
import FooterMain from "./FooterMain";
const PrivacyPolicy = () => {
  return (
    <>
      <Container className="privacy-policy-wrapper">
        <Row>
          <Col>
            <h1>Политика за поверителност </h1>
            <p>
              Тази политика за поверителност описва как събираме, използваме,
              съхраняваме, защитаваме и споделяме Вашата информация, когато
              използвате уебсайта, услугите, функциите или продуктите на Finest
              Copy (наричани заедно „Услугите“). Моля, прочетете тази политика
              внимателно, за да разберете напълно какво информация, която
              събираме и как я използваме. Използвайки нашите Услуги, Вие се
              съгласявате да бъдете обвързани с условията на тази политика за
              поверителност, както е обяснено в нашите Общи условия, които се
              намират тук. Ако имате въпроси или коментари относно нашата
              политика или практики за поверителност, моля, свържете се с нас на
              office@finestcopy.com. Тази политика за поверителност е в сила от
              1 юни 2018 г.
            </p>
            <h2>РАЗДЕЛ 1 – СЪБИРАНЕ НА ИНФОРМАЦИЯ</h2>
            <p>
              Ние събираме следната информация, когато се свържете с нас за
              нашите услуги: Вашето име, имейл адрес, име на фирма и друга
              информация за контакт. Използваме тази информация, за да отговорим
              на Вашите въпроси, да се свържем с Вас относно нашите услуги и да
              Ви уведомим за фирмени новини и промоции. Ако вече не желаете да
              получавате определени известия по имейл, можете да се откажете по
              всяко време, като следвате връзката за отписване, разположена в
              долната част на всяко съобщение, или като ни изпратите имейл на
              office@finestcopy.com.
              <br />
              <b>Социални медии и джаджи.</b>
              <br />
              Нашите Услуги включват функции и уиджети за социални медии, като
              бутона „Харесва ми във Facebook“ и бутона „Сподели това“. Тези
              функции може да събират информация, като Вашия IP адрес и
              страниците, които посещавате на нашия уебсайт, и могат да зададат
              бисквитка, за да функционира правилно. Тези функции и уиджети се
              хостват или от трета страна, или директно на нашия уебсайт. Вашите
              взаимодействия с всяка конкретна функция се управляват от
              политиката за поверителност на компанията, предоставяща тази
              функция. <br />
              <b>Проучвания/конкурси.</b> <br />
              Също така получаваме информация за Вас, когато участвате в: (1)
              проучвания относно нашите Услуги; или (2) лотарии или състезания
              чрез Услугите. Участието в тези области е доброволно и
              следователно имате избор дали да разкриете или не тази информация.
              Ние използваме тази информация, за да уведомяваме победителите и
              да даваме награди, да наблюдаваме трафика и да персонализираме и
              подобряваме нашите Услуги.
              <br />
              <b>Покани приятел. </b>
              <br />
              Finest Copy може да ви предложи възможността да поканите Вашите
              контакти от сайтове на социални медии на трети страни, като
              Facebook или други списъци с контакти. Информацията за контакт,
              която получаваме по този начин, ще се използва само за комуникация
              с адресатите. Вие или третата страна може да се свържете с нас на
              office@finestcopy.com, за да поискате да изтрием тази информация.
              <br />
              <b>Регистрационни данни.</b> <br />
              Когато използвате нашите Услуги, нашите сървъри автоматично
              записват определена информация („Регистрационни данни“).
              Регистрационните данни включват информация като Вашия IP адрес,
              тип браузър, операционна система, препращащата уеб страница,
              посетени страници, местоположение, Вашия мобилен оператор,
              идентификатори на устройство и приложение, думи за търсене и
              информация за бисквитки. Ние използваме регистрационни данни, за
              да предоставяме, измерваме, персонализираме, разработваме,
              анализираме и подобряваме нашите Услуги. <br />
              <b>Бисквитки.</b> <br />
              Бисквитката е малък текстов файл, който прехвърляме на Вашия
              компютър (включително мобилно устройство), за да идентифицираме
              този компютър и да „запомним“ неща за Вашето посещение, като
              Вашите предпочитания, потребителско име и парола. Информацията в
              бисквитката може да бъде свързана с лична информация, като Вашето
              потребителско име. Използваме тази информация, за да подобрим
              нашите Услуги и да приспособим препоръките към Вашите интереси.
              Можете да деактивирате бисквитките по всяко време, въпреки че е
              възможно да нямате достъп или да използвате определени функции на
              нашите Услуги. <br />
              <b>Изчистване на Gif файлове (уеб маяци/уеб грешки).</b>
              <br />
              Ние използваме или нашият партньор от трета страна за помощна
              програма за проследяване използва софтуерна технология, наречена
              ясни gifs (или „Уеб маяци“ или “web beacon“), която ни помага да
              управляваме по-добре съдържанието на нашия уебсайт, като ни
              информира кое съдържание е ефективно. Clear gifs са малки графики
              с уникален идентификатор, подобни по функция на бисквитките, и се
              използват за проследяване на онлайн движенията на нашите
              потребители. За разлика от бисквитките, които се съхраняват на
              твърдия диск на компютъра на потребителя, ясните gifs се вграждат
              невидимо в уеб страници и имейли и са с размер приблизително
              колкото точката в края на това изречение. Може да свържем тази
              информация с вашия IP адрес или идентификатор на устройство, но не
              обвързваме информацията, събрана от ясни GIF файлове, с вашата
              лична информация.
              <br />
              <b>Услуги на трети страни.</b> <br />
              Нашите Услуги съдържат инструменти за проследяване и анализ на
              трети страни от доставчици на услуги, като Google Analytics. Тези
              трети страни могат да използват бисквитки, API и SDK, за да
              събират и анализират потребителска информация от наше име. Третите
              страни може да имат достъп до информация като идентификатор на
              Вашето устройство, MAC адрес, IMEI, локал, информация за
              географско местоположение и IP адрес. Нашата политика за
              поверителност не обхваща използването на тези инструменти за
              проследяване от трети страни. Ние нямаме достъп или контрол върху
              тези трети страни.
              <b> Рекламни мрежи.</b> <br />
              Възможно е да включваме реклами в нашите Услуги. Рекламодателите
              могат да събират и използват информация за Вас, като Вашата
              активност в сесията, идентификатор на устройството, MAC адрес,
              IMEI, информация за географско местоположение и IP адрес. Те могат
              да използват тази информация, за да предоставят реклами, които
              представляват интерес за Вас. Тези трети страни може да доставят
              бисквитки на Вашия компютър, така че съдържанието, до което имате
              достъп, и рекламите, които виждате, могат да бъдат проследени. Тъй
              като рекламните компании на трети страни свързват компютъра Ви с
              номер, те ще могат да разпознават компютъра Ви всеки път, когато
              Ви изпратят реклама. Тези рекламодатели могат да използват
              информация за Вашите посещения на нашите Услуги и сайтове и
              приложения на трети страни, за да измерват ефективността на
              рекламите и да предоставят реклами за стоки и услуги, които
              представляват интерес за Вас. Тази политика за поверителност не се
              прилага за тях и ние не носим отговорност за практиките за
              събиране на данни на тези рекламодатели трети страни. Препоръчваме
              Ви да проверите техните политики за поверителност, за да научите
              повече за използването на бисквитки и други технологии от тяхна
              страна.
            </p>
            <h2>РАЗДЕЛ 2 – ИЗПОЛЗВАНЕ НА ИНФОРМАЦИЯ</h2>
            <p>
              Ние не използваме Вашата лична информация, освен както е описано в
              тази политика за поверителност. В допълнение към употребите,
              описани в тази политика, ние използваме информацията, събрана от
              нашите Услуги за следните цели:
              <br />
              За предоставяне на нашите Услуги;
              <br />
              За да управляваме, рационализираме, персонализираме, развиваме,
              анализираме и подобряваме нашите Услуги;
              <br />
              За да разберем Вашите предпочитания, за да подобрим изживяването
              Ви с нашите Услуги;
              <br />
              За да отговаряме на Вашите коментари и въпроси и да предоставяме
              обслужване на клиенти;
              <br />
              За предоставяне и доставка на заявени от Вас продукти и услуги;
              <br />
              За да ви изпращаме информация, свързана с нашите Услуги,
              включително потвърждения, фактури, технически бележки,
              актуализации, сигнали за сигурност и съобщения за поддръжка и
              администриране;
              <br />
              За да комуникираме с вас относно промоции, награди, предстоящи
              събития и други новини относно продукти и услуги, предлагани от
              Finest Copy и нашите избрани партньори;
              <br />
              За диагностициране или отстраняване на технологични проблеми;
              <br />
              За да комбинираме Вашата информация с друга информация, която
              получаваме от трети страни, за да разберем Вашите предпочитания и
              да Ви предоставим по-добри услуги;
              <br />
              За да Ви продаваме, включително като Ви предоставяме
              персонализирани реклами.
              <br />
            </p>
            <h2>РАЗДЕЛ 3 – РАЗКРИВАНЕ НА ВАШАТА ИНФОРМАЦИЯ</h2>
            <p>
              Ние не споделяме Вашата лична информация, освен както е описано в
              тази политика за поверителност. Възможно е да споделим Вашата
              информация при следните обстоятелства:
              <br />
              Вашето съгласие. Може да споделим Вашата информация по Ваше
              указание.
              <br />
              Доставчици на услуги. От време на време използваме доставчици на
              услуги трети страни, за да ни помогнат в предоставянето на
              Услугите. Може да споделяме Вашата информация с тези доставчици на
              услуги, но само за да могат да изпълняват услугите си. Всяка
              информация, споделена по този начин, ще бъде предмет на задължения
              за поверителност в съответствие с нашата Политика за поверителност
              и ще бъде споделена при условие, че трети страни използват
              информацията само от наше име и в съответствие с нашите
              инструкции.
              <br />
              Закон и вреда. Можем да запазим или разкрием Вашата информация,
              ако смятаме, че това е разумно необходимо за спазване на закон,
              наредба или правно искане; за защита на безопасността на всяко
              лице; за справяне или разследване на измами, сигурност или
              технически проблеми; или за защита на правата или собствеността на
              Finest Copy.
              <br />
              Бизнес трансфери. В случай, че Finest Copy участва в банкрут,
              сливане, придобиване, реорганизация или продажба на активи, Вашата
              информация може да бъде продадена или прехвърлена като част от
              свързани транзакции. Разпоредбите в тази политика за поверителност
              ще се прилагат за Вашата информация, прехвърлена на новото
              юридическо лице.
              <br />
              Нелична информация. Може да споделяме Вашата нелична, обобщена
              информация (като Вашия публичен потребителски профил, публично
              съдържание или броя потребители, които са кликнали върху
              определена връзка) с рекламодатели, издатели, бизнес партньори,
              спонсори и други трети лица.
              <br />
            </p>
            <h2>РАЗДЕЛ 4 – ДРУГИ УЕБСАЙТОВЕ И УСЛУГИ</h2>
            <p>
              Някои връзки в нашите Услуги може да Ви насочат далеч от нашите
              Услуги. Ние не носим отговорност за тях и не можем да контролираме
              практиките за поверителност на други сайтове и Ви насърчаваме да
              прочетете техните декларации за поверителност. След като напуснете
              нашия уебсайт, Вие вече не се управлявате от тази политика за
              поверителност или нашите Общи условия.
            </p>
            <h2>РАЗДЕЛ 5 – СИГУРНОСТ</h2>
            <p>
              Finest Copy предприема разумни мерки, за да защити Вашата
              информация от неоторизиран достъп и срещу загуба, злоупотреба или
              промяна от трети страни. Въпреки че полагаме разумни и
              добросъвестни усилия да съхраняваме информацията, събрана чрез
              нашите Услуги, в защитена работна среда, която не е публично
              достъпна, не можем да гарантираме нейната абсолютна сигурност по
              време на нейното предаване или съхранение.
              <br />
              Освен това, докато се опитваме да гарантираме целостта и
              сигурността на нашата мрежа и системи, не можем да гарантираме, че
              нашите мерки за сигурност ще попречат на „хакери“ на трети страни
              да получат незаконен достъп до тази информация. Ние не
              гарантираме, че Вашата информация ще бъде защитена срещу загуба,
              злоупотреба или промяна от трети страни. Никой метод за предаване
              през интернет или метод за електронно съхранение обаче не е 100%
              сигурен. Поради това не можем да гарантираме неговата абсолютна
              сигурност.
              <br />
              Ние се стремим нашите сървъри и връзки да включват най-новите
              устройства за криптиране и сигурност. За да предотвратим
              неоторизиран достъп, ние сме въвели физически, електронни и
              управленски процедури за защита и поверителност на информацията,
              която събираме. Ние използваме Secure Sockets Layer (SSL),
              усъвършенстван протокол за сигурност, който защитава информацията
              за Вашата банкова карта и гарантира сигурна онлайн поръчка. SSL
              интернет връзките са криптирани и по този начин защитават цялата
              информация за поръчка на банковата карта, включително Вашето име,
              адрес и номер на банкова карта, така че да не може да бъде
              прочетена при пренасяне. Моля, имайте предвид следното: въпреки че
              използваме индустриални стандартни мерки за сигурност, за да
              защитим сигурността на Вашата лична информация, никакви
              предавания, извършени по или през интернет, и нито един метод за
              електронно съхранение не са гарантирани, че са сигурни.
            </p>
            <h2>РАЗДЕЛ 6 – НАШИТЕ ПОЛИТИКИ ОТНОСНО ДЕЦАТА</h2>
            <p>
              Ние съзнателно не събираме и не искаме лична информация от лица
              под 13 години, нито съзнателно позволяваме на деца под 13 години
              да използват, имат достъп или да се регистрират за нашите Услуги.
              Ако сте под 13 години, моля, не ни изпращайте информация за себе
              си. Никой под 13 години не може да предоставя лична информация. В
              случай, че научим, че сме събрали лична информация от дете под 13
              години, ние ще изтрием тази информация възможно най-бързо. Ако
              смятате, че може да разполагаме с информация от или за дете под 13
              години, моля свържете се с нас на office@finestcopy.com
            </p>
            <h2>РАЗДЕЛ 7 – СИГНАЛИ “НЕ ПРОСЛЕДЯВАЙТЕ” </h2>
            <p>
              Някои уеб браузъри предлагат сигнал „Не проследявайте“ („DNT“).
              DNT сигналът е HTTP заглавно поле, което показва Вашите
              предпочитания по отношение на проследяване на потребители между
              сайтове. В момента не отговаряме на DNT сигнали. Ние също така
              работим с трети страни, които използват технологии за проследяване
              на нашите Услуги, за да предоставяме персонализирани реклами от
              наше име и от името на други рекламодатели в Интернет. Тези
              компании могат да събират информация за Вашата дейност в нашите
              Услуги и взаимодействието Ви с нашите рекламни и други
              комуникации, и да използват тази информация, за да определят кои
              реклами виждате на уебсайтове и приложения на трети страни.
            </p>
            <h2>РАЗДЕЛ 8 – АКТУАЛИЗИРАНЕ НА ТАЗИ ПОЛИТИКА</h2>
            <p>
              Възможно е да актуализираме тази политика за поверителност, за да
              отразим промените в нашите информационни практики. Ако направим
              съществени промени, ще Ви уведомим по имейл (изпратен на имейл
              адреса, посочен във Вашия акаунт) или чрез публикуване на известие
              на нашия уебсайт, преди промяната да влезе в сила. Като част от
              Вашето задължение за използване на нашите Услуги, вие се
              съгласявате периодично да преглеждате тази страница за най-новата
              информация относно нашите практики за поверителност.
            </p>
            <h2>РАЗДЕЛ 9 – ПРОМЯНА НА ВАШАТА ЛИЧНА ИНФОРМАЦИЯ</h2>
            <p>
              Ако Вашата лична информация се промени или ако вече не желаете да
              използвате нашите Услуги, можете да промените или изтриете
              информацията си, като редактирате настройките на акаунта си или
              като се свържете с нас на office@finestcopy.com. Ние ще отговорим
              на Вашето запитване в рамките на 30 дни.
            </p>
            <h2>РАЗДЕЛ 10 – ИЗБИРАНЕ НА НАШИЯ МАРКЕТИНГ</h2>
            <p>
              Можете да се откажете от получаването на промоционални имейли от
              нас, като следвате инструкциите в тези имейли или като ни
              изпратите имейл на office@finestcopy.com. Ако се откажете, пак
              може да Ви изпращаме непромоционални имейли, като например имейли
              за Вашия акаунт или нашите текущи бизнес отношения.
            </p>
            <h2>РАЗДЕЛ 11 – ЗАПАЗВАНЕ НА ДАННИ</h2>
            <p>
              Ние ще запазим Вашата информация, докато акаунтът Ви е активен или
              колкото е необходимо, за да Ви предоставим нашите Услуги. Ако
              искате да закриете своя акаунт или да поискате повече да не
              използваме информацията Ви, за да Ви предоставяме услуги, свържете
              се с нас на office@finestcopy.com. Ние също така ще запазим и
              използваме Вашата информация, ако е необходимо, за да спазваме
              нашите законови задължения, да разрешаваме спорове и да прилагаме
              нашите споразумения.
            </p>
            <h2>РАЗДЕЛ 12 – МЕЖДУНАРОДНИ ПОТРЕБИТЕЛИ</h2>
            <p>
              Подобно на много уебсайтове, нашите Услуги могат да бъдат достъпни
              от международна аудитория. Използвайки нашите Услуги, Вие се
              съгласявате, че Вашата лична информация може да бъде използвана за
              целите, посочени в тази политика. Освен това, такива данни може да
              се съхраняват на сървъри, разположени извън Вашата юрисдикция и в
              юрисдикции, които може да имат по-малко строги практики за
              поверителност от Вашата собствена. Предоставяйки ни Вашите данни,
              Вие се съгласявате с прехвърлянето на Вашите данни от Вашата
              страна или юрисдикция към други държави или юрисдикции по света.
            </p>
            <h2>РАЗДЕЛ 13 – ИНФОРМАЦИЯ ЗА КОНТАКТИ</h2>
            <p>
              Ако имате въпроси или коментари относно нашата политика или
              практики за поверителност, моля, свържете се с нас на имейл адрес:
              office@finestcopy.com
            </p>
            <br />
            <br />
            <h3>Правила и условия</h3>
            <p>
              ТЕЗИ УСЛОВИЯ ЗА УСЛУГИ ЗА ДИГИТАЛЕН МАРКЕТИНГ И УЕБ РАЗРАБОТКА
              (Условията) са интегрирано споразумение между Клиент и Компания,
              състоящо се от тези Условия, условията, съдържащи се във
              фактурата, издадена от Компанията на Клиента и препращащи към тези
              Условия (Условията), всяко споразумение(я)), сключени между
              Клиента и Дружеството и всички прикачени файлове, инструкции или
              изисквания, свързани с което и да е от горепосочените (всяко от
              горепосочените, съставляващо това споразумение и наричано тук
              Споразумението). Това Споразумение е обвързващо по отношение на
              целия бизнес, извършван между Клиент и Компания, и се прилага и
              контролира всички споразумения, свързани с предоставянето на
              услуги от Компанията на Клиента.
              <br />
              <br />
              Дефиниции. Всеки термин с главна буква, използван в това
              Споразумение, но недефиниран по друг начин, има значението,
              посочено по-долу: <br />
              1.1 “Компанията” означава Гоу Фърст ЕООД, БЪЛГАРИЯ. <br />
              1.2 Поверителна информация означава поверителна, частна или
              непублична информация относно Компанията или нейния бизнес,
              независимо дали е ограничена до писмен вид или е маркирана като
              поверителна, частна или непублична. Поверителната информация
              конкретно ще включва всяка и цялата информация относно
              патентованите дизайни, продукти и услуги на Компанията, всяка
              тяхна част и всякакви подобрения, нововъведения, допълнения,
              увеличения, промени, модификации или модификации към тях от
              всякакъв вид или естество. <br />
              Поверителната информация включва също, но не се ограничава до
              следните типове информация и друга информация от подобно естество:
              произведения, открития, търговски тайни, идеи, концепции, проекти,
              лични договорни отношения, проекти, курсове на сделки, чертежи,
              спецификации, подобрения, изобретения, оригинални авторски
              произведения, свързани с Компанията (включително ръководства),
              проекти на сгради и планове, техники, модели, данни, документация,
              диаграми, диаграми, изследвания, разработки, процеси, формули,
              компютърни системи, данни, алгоритми, изходни кодове, обектни
              кодове, html кодове и скриптове, процедури, методологии,
              „ноу-хау“, маркетингови техники и материали, планове за маркетинг
              и развитие, имена и списъци на действителни и потенциални клиенти
              и доставчици, списъци с водещи клиенти и друга информация,
              свързана с клиенти и доставчици, ценови листи, ценови политики,
              методи за провеждане и получаване на бизнес и финансова
              информация. Поверителната информация също така включва всяка
              информация, описана тук, която Компанията получава от трета страна
              и която Компанията третира като собствена или поверителна,
              независимо дали е собственост или не е разработена от Компанията.{" "}
              <br />
              Поверителната информация обаче не включва:
              <br />
              I. каквато и да е информация, която вече е в притежание на Клиента
              към момента на разкриване от Компанията по причини, които не са
              свързани с взаимоотношенията на Клиента с Компанията, както е
              показано от файловете и записите на Клиента, съществуващи преди
              време на разкриване от Дружеството;
              <br />
              II. всяка информация, която е независимо разработена от Клиента
              извън обхвата и несвързана с отношенията на Клиента с Компанията и
              без препратка към или разчитане на каквато и да е Поверителна
              информация, както е показано от файловете и записите на Клиента
              преди момента на разкриване от Компанията ;<br />
              III. всякаква информация, която по всяко време е предоставена на
              Клиента от трета страна без нарушение на задълженията на Клиента
              по настоящото или нарушение на задълженията на такава трета страна
              към Компанията или към друга страна; или
              <br />
              IV. информация и бизнес взаимоотношения, развити впоследствие от
              Клиента чрез използването на данни от Клиента в съответствие с
              условията на настоящото.
              <br />
              1.3 “Клиент” означава субектът, който поръчва Услугите и е
              отговорен за плащането на таксите съгласно и спазването на това
              Споразумение.
              <br />
              1.4 “Дата на влизане” в сила означава денят, който е по-ранен от
              страна на Клиента:
              <br />
              I. връща на Компанията подписано копие от Условията; или II.
              предлагане на плащане към Компанията в съответствие с Условията.
              <br />
              1.5 “Лице” означава всяко физическо лице, корпорация, дружество с
              ограничена отговорност, тръст, съвместно предприятие, асоциация,
              компания, командитно или събирателно дружество, неперсонифицирана
              организация, правителствен орган или друго образувание. Фирмени
              услуги.
              <br />
              2.1 “Услуги”. Компанията предоставя на клиента такива услуги за
              дигитален маркетинг и уеб разработка, както е посочено в
              Условията, което може да включва, без ограничение, разработка и
              поддръжка на уебсайтове, оптимизация за търсачки и реклама в
              социални медии („Услугите“). Споразумение с клиента.
              <br />
              3.1. Представяне и гаранции. Клиентът е отговорен за собствената
              си отговорност към комуникациите и запитванията от Компанията и
              признава, че всяка липса на отговорност може съществено да повлияе
              на ефективността на Услугите. Клиентът декларира и гарантира, че
              доколкото му е известно, всяка информация или данни, които
              Клиентът, включително неговите агенти или представители, е
              предоставил или ще предостави на Компанията във връзка с Услугите,
              е и ще бъде точна и пълна. Клиентът декларира и гарантира, че
              всяка информация, предоставена на Компанията съгласно това
              Споразумение, не нарушава приложим закон или наредба; не нарушава
              по никакъв начин права на трети страни, включително авторско
              право, патент, търговска марка, търговска тайна или друго право на
              интелектуална собственост, или право на поверителност или
              публичност; не е невярно или подвеждащо; не е и няма да доведе до
              каквато и да е измама на потребителя, отговорност за продукта,
              нарушение на договор, нараняване, повреда или вреда от какъвто и
              да е вид на което и да е физическо или юридическо лице; не е
              клеветническа, клеветническа, клеветническа или заплашителна; не
              съдържа вируси; не съдържа, рекламира или предлага каквато и да е
              форма на шпионски софтуер, рекламен софтуер или друг софтуер за
              реклама или събиране на информация; и не съдържа, свързва или
              насърчава насилие, престъпления от омраза (независимо дали на
              расова или друга основа), незаконни дейности или дискриминация,
              основана на раса, пол, религия, националност, увреждане, сексуална
              ориентация или възраст.
              <br />
              3.2. Без грешка. Клиентът потвърждава, че трети страни могат да
              получат достъп до уебсайта(овете) на Клиента и следователно няма
              да държат Дружеството или нейните филиали отговорни за дейностите
              на посетителите, които идват на уебсайта(ите) на Клиента в
              резултат на Услугите. Клиентът потвърждава и се съгласява, че няма
              да държи Компанията или нейните филиали отговорни за грешки в
              съдържанието, пропуски, последствия, щети, разходи,
              възстановявания или отстъпки от всякакъв вид, произтичащи от
              прекъсване на услугата или друга недостъпност на интернет или
              уебсайт. Освен това, Клиентът потвърждава и се съгласява, че
              грешки или нарушения в изпълнението на Услугите, включително, но
              не само правописни грешки или неправилна комуникация, не създават
              право на възстановяване на средства за Клиента. Клиентът ще
              уведоми Компанията своевременно и ще предостави на Компанията
              разумна възможност след това да коригира всички идентифицирани
              грешки или пропуски. Клиентът потвърждава, че Дружеството не прави
              никакви декларации или гаранции, свързани с резултатите от
              Услугите, включително, без ограничение, броя импресии, кликвания
              или потенциални клиенти, както и всеки промоционален ефект или
              възвръщаемост на инвестицията от тях. Ако Компанията разчита на
              трети страни за определени данни, Компанията не дава никакви
              гаранции по отношение на точността, надеждността или пълнотата на
              каквито и да било такива данни, включително статистически данни за
              употребата.
              <br />
              Срок.
              <br />
              4.1 Ефективност на Споразумението. Клиентът се съгласява да бъде
              обвързан от това Споразумение, което влиза в сила от датата на
              влизане в сила от 1 юни 2018 г.
              <br />
              4.2 Срок на споразумението. Това Споразумение остава в сила от
              Датата на влизане в сила, докато Клиентът завърши плащането на
              всички фактурирани суми, или както е предвидено в Условията, което
              от двете настъпи по-късно, с изключение на всички клаузи на това
              Споразумение, които остават в сила след прекратяването.
              <br />
              4.3 Прекратяване. Независимо от каквото и да било противно,
              съдържащо се тук, Дружеството може да прекрати това Споразумение и
              незабавно да прекрати предоставянето на Услугите по него в случай:
              <br />
              a. на нарушение от страна на Клиента на това Споразумение;
              <br />
              b. че всяка декларация или гаранция, направени от Клиента по-долу,
              са разумно определени от Компанията като неверни или неточни; или
              <br />
              c. че, според разумното решение на Компанията, Клиентът използва
              Услугите в опит да заплашва, сплашва, дразни, злоупотребява или
              тормози или по друг начин използва Услугите за насърчаване на
              незаконни практики. В случай на някое от гореизброените и ако
              Компанията желае да упражни правото си да прекрати по-долу,
              Компанията изпраща известие до Клиента, описващо нарушението, и
              след това, ако проблемът остане неразрешен в продължение на
              петнадесет дни, това Споразумение се прекратява автоматично.
              <br />
              4.4 Отказ за предоставяне на услугите. Независимо от каквото и да
              е противно, съдържащо се тук, Компанията може да откаже да
              предостави Услугите по-долу, ако по свое усмотрение Компанията
              реши, че всяка информация, материал или съдържание, които ще бъдат
              използвани във връзка с Услугите, е неприемливо, неподходящо или
              вредно за репутацията на Компанията. Горното включва, без
              ограничение, всякакви графики. Разни.
              <br />
              5.1 Без гаранция. Освен ако не е договорено в писмена форма,
              Компанията не дава изрични, подразбиращи се или имплицитни
              гаранции по отношение на ефективността на Услугите.
              <br />
              5.2 Поверителна информация. Всяка страна потвърждава, че по време
              на действие на Споразумението може да има достъп до определена
              „Поверителна информация“, както е дефинирана по-долу, на другата
              страна. По време на срока на настоящото Споразумение и за период
              от две години след прекратяването на настоящото Споразумение по
              каквато и да е причина, в рамките на географския регион на
              България и неговите територии, всеки получател на Поверителна
              информация трябва:
              <br />
              a. да не разкрива Поверителната информация на трета страна,
              <br />
              b. да не използва Поверителната информация по какъвто и да е
              начин, освен за целите на изпълнение съгласно настоящото
              Споразумение,
              <br />
              c. да полага разумни грижи, за да предотврати разкриването на
              Поверителната информация и<br />
              d. незабавно да уведоми разкриващата страна за всяко неразрешено
              разкриване или използване на поверителна информация. При
              прекратяване на настоящото Споразумение по каквато и да е причина,
              всяка страна трябва незабавно да достави на другата страна всички
              копия на Поверителната информация, получена от тази друга страна.
              Всяка страна признава, че нарушението на съдържащите се тук
              споразумения ще причини непоправима вреда на разкриващата страна,
              давайки право на разкриващата страна на съдебна забрана, наред с
              други средства за правна защита, срещу всяко нарушение или заплаха
              за нарушение, без да се отказва или засяга иск за обезщетение или
              друго облекчение.
              <br />
              5.3 Непривличане. По време на срока на настоящото Споразумение и
              за една година след това Клиентът няма право пряко или непряко,
              самостоятелно или съвместно с други, от собствено име или от името
              на което и да е друго физическо или юридическо лице, да изисква
              или да се опитва да убеди или да прикани който и да е служител на
              Компанията да прекрати работата си с Компанията или да работи за
              всеки, който се конкурира с Компанията.
              <br />
              5.4 Разумност на определени условия. Страните декларират, че
              териториалните и времевите ограничения, посочени тук, са разумни и
              правилно необходими за адекватната защита на бизнеса на всяка от
              страните. В случай, че някое от териториалните или времевите
              ограничения се счете за неразумно от компетентен съд, всички
              страни се съгласяват и се подчиняват на намаляването на
              териториалното или времевото ограничение, както съдът сметне за
              разумно.
              <br />
              5.5 Форсмажорни обстоятелства. С изключение на задълженията за
              плащане, нито една от страните не носи отговорност пред другата за
              закъснения или пропуски в изпълнението, произтичащи от причини
              извън разумния контрол на тази страна, включително, но не само,
              стихийни бедствия, трудови спорове или безредици, материален
              недостиг или дажби , бунтове, военни действия, правителствени
              разпоредби, комуникационни или комунални повреди или жертви.
              <br />
              5.6 Отношения на страните. Страните са независими изпълнители по
              силата на това Споразумение и не се предвиждат никакви други
              взаимоотношения, включително партньорство, франчайзинг, съвместно
              предприятие, агенция, работодател/служител, доверено лице,
              отношения между господар/служител или други специални отношения.
              Нито една от страните няма право да действа по начин, който
              изразява или предполага връзка, различна от тази на независим
              изпълнител или обвързваща другата страна.
              <br />
              5.7 Без трети страни бенефициенти. Освен ако изрично не е
              предвидено друго, никакви разпоредби на това Споразумение не са
              предназначени или не трябва да се тълкуват като предоставящи или
              даващи на което и да е физическо или юридическо лице, различно от
              Дружеството и Клиента, каквито и да било права, средства за защита
              или други облаги съгласно или поради това Споразумение. Дъщерните
              дружества и свързаните лица на Дружеството са изрични трети страни
              бенефициенти по това Споразумение.
              <br />
              5.8 Съдебна защита по справедливост. Всяка страна признава, че
              нарушение от другата страна на която и да е разпоредба за
              поверителност или права на собственост на това Споразумение може
              да причини на страната, която не е нарушила, непоправими щети, за
              които присъждането на обезщетение не би било адекватно
              обезщетение. Следователно, страната, която не е нарушила, може да
              заведе иск за освобождаване на нарушилата страна от всякакви и
              всички действия в нарушение на тези разпоредби, което средство за
              защита ще бъде кумулативно, а не изключително, и с настоящото
              всяка от страните се съгласява с вписването на забрана от който и
              да е съд с компетентна юрисдикция, постановяващ всяко нарушение
              или заплаха от нарушение на тези разпоредби, в допълнение към
              всяко друго обезщетение, на което страната, която не е нарушила,
              може да има право по закон или по справедливост.
              <br />
              5.9 Адвокатски хонорари. В допълнение към всяко друго присъдено
              облекчение, победилата страна във всеки иск, произтичащ от това
              Споразумение, има право на своите разумни адвокатски хонорари и
              разходи.
              <br />
              5.10 Бележки. Всяко известие, което се изисква или е позволено да
              бъде дадено от която и да е от страните по настоящото
              Споразумение, трябва да бъде в писмена форма и да бъде доставено
              лично или изпратено от реномирана куриерска служба за нощувка или
              с първокласна поща - заверена или препоръчана, до вниманието на
              президента на другата страна. Известията ще се считат за влезли в
              сила два работни дни след изпращане, с предплатена поща, ако са
              изпратени по пощата, или на следващия ден, ако са изпратени с
              куриерска служба за една нощ. Известията ще отиват, в случай на
              Клиент, на адреса, посочен в Условията, или, в случая на
              Компанията, до нашия щаб, както е предвидено на нашия уебсайт или
              в Условията, или до всякакви други адреси, предоставени
              впоследствие в писане от страна.
              <br />
              5.11 Присвояване. Клиентът не може да прехвърля правата си или да
              делегира задълженията си по настоящото, изцяло или частично, по
              силата на закон или по друг начин, без предварителното писмено
              съгласие на Компанията. Всеки опит за възлагане или делегиране без
              писменото съгласие на Компанията ще бъде невалиден. Правата и
              задълженията на страните по това Споразумение ще бъдат обвързващи
              и влезли в сила в полза на съответните приемници и разрешени
              правоприемници на страните. За целите на тази разпоредба, петдесет
              процента или повече промяна в контрола върху собствеността върху
              капитала на Клиента представлява прехвърляне. Всяко прехвърляне
              или прехвърляне от страна на Клиента по силата на закон, сливане,
              консолидация, прехвърляне на мнозинството от капитала с право на
              глас на Клиента или всяка подобна сделка или поредица от свързани
              транзакции ще бъде прехвърляне, забранено от тази разпоредба.
              <br />
              5.11 Присвояване. Клиентът не може да прехвърля правата си или да
              делегира задълженията си по настоящото, изцяло или частично, по
              силата на закон или по друг начин, без предварителното писмено
              съгласие на Компанията. Всеки опит за възлагане или делегиране без
              писменото съгласие на Компанията ще бъде невалиден. Правата и
              задълженията на страните по това Споразумение ще бъдат обвързващи
              и влезли в сила в полза на съответните приемници и разрешени
              правоприемници на страните. За целите на тази разпоредба, петдесет
              процента или повече промяна в контрола върху собствеността върху
              капитала на Клиента представлява прехвърляне. Всяко прехвърляне
              или прехвърляне от страна на Клиента по силата на закон, сливане,
              консолидация, прехвърляне на мнозинството от капитала с право на
              глас на Клиента или всяка подобна сделка или поредица от свързани
              транзакции ще бъде прехвърляне, забранено от тази разпоредба.
              <br />
              5.12 Отказ и промяна. Неуспехът на която и да е от страните да
              приложи която и да е разпоредба на това Споразумение няма да се
              счита за отказ от бъдещо прилагане на тази или друга разпоредба.
              Всеки отказ, изменение или друга модификация на която и да е
              разпоредба на това Споразумение ще бъде в сила само ако е в писмен
              вид и е подписано от страните.
              <br />
              5.13 Разделимост. Ако по някаква причина компетентен съд установи,
              че която и да е разпоредба от това Споразумение е неприложима,
              тази разпоредба от Споразумението ще бъде приложена в максимално
              допустимата степен, за да осъществи намерението на страните, а
              останалата част от настоящото Споразумение ще продължи в пълна
              сила и действие.
              <br />
              5.14 Закон за контрол, юрисдикция. Това Споразумение и всяко
              действие, свързано с него, се управляват, контролират, тълкуват и
              определят от и според законите на щата Калифорния и Съединените
              американски щати, без да се вземат предвид разпоредбите за
              стълкновение на закона. Страните по настоящото се съгласяват с
              изключителната юрисдикция на федералните и щатските съдилища на
              Ориндж Каунти, Калифорния. Страните се отказват от всякакви
              възражения, които могат да имат по отношение на юрисдикцията и
              мястото за изпълнение на каквото и да е решение в такива съдилища.
              <br />
              5.15 Заглавия. Заглавията, използвани в това споразумение, са само
              за улеснение и не трябва да се използват за тълкуване на който и
              да е аспект от настоящото споразумение.
              <br />
              5.16 Пълно споразумение. Това Споразумение, включително всички
              документи или експонати, които са включени тук чрез препратка,
              представлява цялото споразумение между страните по отношение на
              предмета на него и отменя и заменя всички предишни и текущи
              договорености или споразумения, писмени или устни, по отношение на
              този предмет ; при условие, че в случай на несъответствие между
              това Споразумение и правилата или условията, свързани с Гоу Фърст
              ЕООД, тези Условия имат предимство.
              <br />
              5.17 Обезщетение. Всяка страна „Обезщетяваща страна“ трябва да
              обезщети другата/ите страна/и и нейните директори, служители,
              агенти, служители, изпълнители, филиали или дъщерни дружества,
              наричани заедно „Обезщетените страни“, и да предпази Обезщетените
              страни от и срещу всяка отговорност, загуби, разходи, щети и
              такси, включително разумни адвокатски хонорари, понесени от
              Обезщетените страни във връзка с всяко нарушение от Обезщетяващата
              страна на която и да е гаранция, споразумение или задължение по
              настоящото Споразумение.
              <br />
              5.18 Ограничена отговорност. Сумата в лева на всяка отговорност на
              Компанията по настоящото Споразумение или на обезщетението на
              Компанията по настоящото Споразумение ще бъде ограничена до
              сумата, действително платена на Компанията за Услугите по
              настоящото Споразумение.
              <br />
              5.19 Кумулативни средства за защита. Правата и средствата за
              защита съгласно това Споразумение са кумулативни и са в допълнение
              към, а не вместо други права и средства за защита, налични по
              закон, в капитала или по друг начин.
              <br />
              5.20 Допълнителни гаранции. Всяка страна по това Споразумение ще
              предприеме с разумно усърдие всички такива действия и ще изпълни
              всички такива документи, които могат да бъдат разумно необходими
              за изпълнение и изпълнение на намерението на това Споразумение.
              <br />
              <br />
              Тези Услуги се управляват и предоставят от Гоу Фърст ЕООД. Ако
              имате въпроси относно тези Условия, моля, свържете се с нас.
            </p>
          </Col>
        </Row>
      </Container>
      <FooterMain />
    </>
  );
};

export default PrivacyPolicy;
