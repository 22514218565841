import {
  faFacebook,
  faGoogle,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import {
  faCode,
  faEnvelope,
  faFileText,
  faLineChart,
  faPaintBrush,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SERVICE_CARDS_INFO = [
  {
    title: "Маркетинг чрез съдържание",
    infoText:
      "Маркетингът чрез съдържание е предназначен да помогне на бизнеса да създава и разпространява релевантно съдържание, което привлича и задържа ясно дефинирана аудитория. Като използваме силата на маркетинга на съдържанието, ще подобрим разпознаваемостта на марката, ангажираността и продажбите, като същевременно ви позиционираме като лидери в индустрията.",
    iconCode: <FontAwesomeIcon icon={faFileText} />,
    row: true,
    id: Math.floor(Math.random() * 486748936312),
    path: "/services/content-marketing",
  },
  {
    title: "Копирайтинг",
    infoText:
      "Думите правят повече от това да информират; те подтикват към действие и предизвикват емоция. Затова ефективният копирайтинг е от решаващо значение за успеха на всяка маркетинг кампания. Добре разработеното, оригинално и завладяващо копи може да помогне за увеличаване на ангажираността, продажбите и авторитет на марката.",
    iconCode: <FontAwesomeIcon icon={faFileText} />,
    row: false,
    id: Math.floor(Math.random() * 486748936312),
    path: "/services/copywriting",
  },
  {
    title: "SEO оптимизация ",
    infoText:
      "Професионалната SEO оптимизация е тайното оръжие във Вашия дигитален арсенал. Ние използваме индивидуални стратегии и инструменти, за да подобрим видимостта на Вашия уебсайт, да привлечем висококачествен трафик и да увеличим продажбите. Пригответе се да доминирате в класирането в търсачките и да изместите конкуренцията ",
    iconCode: <FontAwesomeIcon icon={faLineChart} />,
    row: false,
    id: Math.floor(Math.random() * 486748936312),
    path: "/services/seo-optimization",
  },
  {
    title: "Facebook реклама",
    infoText:
      "Facebook рекламата помага на бизнеса да достигне до своите идеални клиенти с таргетирано насочване по различни критерии и завладяващи рекламни послания. Чрез индивидуална стратегия за Facebook реклама ще постигнем маркетинговите цели и ще използваме най-подходящите инструменти в мощната рекламна платформа на Meta за Вашия бизнес",
    iconCode: <FontAwesomeIcon icon={faFacebook} />,
    row: true,
    id: Math.floor(Math.random() * 486748936312),
    path: "/services/facebookAdd",
  },
  {
    title: "Instagram реклама",
    infoText:
      "Рекламата в Instagram предлага допълнителното предимство за създаване на персонализирани аудитории и висока ангажираност на потребителите.Instagram също е отлична платформа за рекламиране на продукти, защото позволява на търговците да се насочат към конкретни демографски групи. Затова Instagram рекламата е отлично допълнение към Facebook рекламата.",
    iconCode: <FontAwesomeIcon icon={faInstagram} />,
    row: false,
    id: Math.floor(Math.random() * 486748936312),
    path: "/services/instagramAdd",
  },
  {
    title: "Google реклама",
    infoText:
      "PPC рекламата работи отлично с други маркетинг канали. PPC рекламата е измерима и проследима форма на реклама, има множество опции за насочване и може да подпомогне потребителите в много етапи от фунията на продажбите по пътя, който изминават от осведомеността до това да станат Ваши клиенти.",
    iconCode: <FontAwesomeIcon icon={faGoogle} />,
    row: false,
    id: Math.floor(Math.random() * 486748936312),
    path: "/services/googleAdd",
  },
  {
    title: "Графичен дизайн",
    infoText:
      "Използването на уникален за бранда графичен дизайн има за цел да отличи Вашата марка. Ще създадем за Вас персонализирани визуални материали, които привличат вниманието на публиката и оставят трайно впечатление. Чрез изграждане на отличителна визуална идентичност ще предадем посланието на Вашата марка по възможно най-ефективния начин.",
    iconCode: <FontAwesomeIcon icon={faPaintBrush} />,
    row: true,
    id: Math.floor(Math.random() * 486748936312),
    path: "/services/graphicDesign",
  },
  {
    title: "Изработка на уебсайт",
    infoText:
      "Уеб дизайнът обхваща не само естетическата визия на уебсайта. Ние създаваме персонализирани, удобни за потребителя дизайни, които са съобразени с Вашите бизнес нужди и цели. Нашите уебсайтове не само изглеждат страхотно на всички устройства, но са оптимизирани за ефективност, привличане на трафик и увеличаване на продажбите.",
    iconCode: <FontAwesomeIcon icon={faCode} />,
    row: false,
    id: Math.floor(Math.random() * 486748936312),
    path: "/services/webDevelopment",
  },
  {
    title: "Email маркетинг",
    infoText:
      "Стратегията за имейл маркетинг е от съществено значение за фирми, които искат да увеличат ангажираността на клиентите и да стимулират продажбите. Ние изграждаме персонализирани имейл кампании, които ще Ви помогнат да изградите силни взаимоотношения с Вашата аудитория, да повишите осведомеността и ангажираността към марката.",
    iconCode: <FontAwesomeIcon icon={faEnvelope} />,
    row: false,
    id: Math.floor(Math.random() * 486748936312),
    path: "/services/emailMarketing",
  },
];

export default SERVICE_CARDS_INFO;
