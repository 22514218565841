import React from "react";
import "./PortfolioPage.scss";
import portfolioInfo from "../../utils/portfolioInfo/portfolioInfo";
import HeroSection from "../common/HeroSection/HeroSection";
import PurpleSection from "../servicec-page/common/PurpleSection";
import WhiteSection from "../servicec-page/common/WhiteSection";
import CalendlyComponent from "../home-page/CalendlyComponent";
import FooterMain from "../Layout/Foooter/FooterMain";
import sectionImg from "../../assets/img/portfolio-purple-section-img.png";
import sectionImg2 from "../../assets/img/portfolio-white-section-img.png";
import PortfolioProjectsSection from "./PortfolioProjectsSection";
import PartnersSlider from "../home-page/PartnersSlider";
import CounterComponentSection from "./CounterComponentSection";
import heroAnimation from "../../assets/json-animations/portfolio-hero-animation.json";

const PortfolioPage = () => {
  return (
    <>
      <HeroSection
        className="content-marketing-hero-section"
        firstHeaderText={portfolioInfo.heroSection.firstHeader}
        secondHeaderText={portfolioInfo.heroSection.secondHeader}
        textArr={portfolioInfo.heroSection.paragraphs}
        paddingLeftImgCol="50px"
        heroAnimation={heroAnimation}
        btnTitle="Запитване"
        btnOnHover="on-hover-purple"
        textColor="section-text-color-purple"
        textAlign="left"
      />

      <PurpleSection
        sectionHeader={portfolioInfo.portfolioPurpleSection.header}
        textArr={portfolioInfo.portfolioPurpleSection.paragraphs}
        textColor="section-text-color-white"
        textAlign="left"
        sectionImg={sectionImg}
        imagePositionLeft="50%"
      />
      <WhiteSection
        sectionHeader={portfolioInfo.portfolioWhiteSection.header}
        textArr={portfolioInfo.portfolioWhiteSection.paragraphs}
        textColor="section-text-color-purple"
        textAlign="left"
        sectionImg={sectionImg2}
        animationBottomPosition="5%"
        animationRightPosition="4%"
      />
      <PartnersSlider />
      <PortfolioProjectsSection />
      <CounterComponentSection />
      <CalendlyComponent />
      <FooterMain />
    </>
  );
};

export default PortfolioPage;
