import React from "react";
import PortfolioTemplate from "../utils/PortfolioTemplate";
import portfolioClientInfo from "../../../../utils/portfolioInfo/portfolioClientInfo";

export default function Tiande() {
  return (
    <PortfolioTemplate
      mainHeaderText={portfolioClientInfo.tiande.mainHeaderText}
      clientData={portfolioClientInfo.tiande.clientData}
      mainImg={portfolioClientInfo.tiande.mainImg}
      mockupImg={portfolioClientInfo.tiande.mockupImg}
    />
  );
}
